import React from "react"
import { Link } from "react-router-dom"

import Cell from "components/Table/components/Cell"

function CellIconLink({ icon: Icon, to, alt }) {
  return (
    <Cell right clickable>
      <Link to={to} alt={alt}>
        <Icon
          width="20"
          title={alt}
          style={{verticalAlign: "middle"}}
        />
      </Link>
    </Cell>
  )
}

CellIconLink.displayName = "Cell.Icon"
CellIconLink.defaultProps = { width: 40 }

export default CellIconLink