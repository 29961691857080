import React, { useState, useMemo, useCallback, createContext } from "react"
import jwtDecode from "jwt-decode"

import authApi from "datasources/auth"
import useLoaderCtx from "hooks/useLoaderCtx"

const KEY = "dailyBonfireTokens.v2"

const Context = createContext()

let refreshReq

const createRolesObj = roles =>
  roles.reduce((obj, role) => ({ ...obj, [role]: true })
, {})

export function Provider({ children }) {
  const initial = useMemo(() => {
    const _tokens = JSON.parse(localStorage.getItem(KEY))
    const _user = _tokens ? jwtDecode(_tokens.accessToken) : undefined
    const _roles = _tokens ? createRolesObj(_user.roles) : undefined
    return { tokens: _tokens, user: _user, roles: _roles }
  }, [])

  const loader = useLoaderCtx()
  const [user, setUser] = useState(initial.user)
  const [tokens, setTokens] = useState(initial.tokens)
  const [roles, setRoles] = useState(initial.roles)

  const { accessToken, refreshToken } = tokens || {}
  const { id: userId } = user || {}

  const auth = useCallback(
    _tokens => {
      const _user = jwtDecode(_tokens.accessToken)
      setTokens(_tokens)
      setUser(_user)
      setRoles(createRolesObj(_user.roles))
      localStorage.setItem(KEY, JSON.stringify(_tokens))
    }, []
  )

  const deauth = useCallback(
    () => {
      setTokens(undefined)
      setUser(undefined)
      setRoles(undefined)
      localStorage.removeItem(KEY)
    },
    []
  )

  const refresh = useCallback(
    async () => {
      try {
        const ctx = { loader }
        const payload = { refreshToken, userId }
        const { data } = await authApi.createToken(ctx, payload)

        auth(data)
        refreshReq = undefined

        return data.accessToken
      } catch (err) {
        deauth()
      }
    },
    [refreshToken, userId, auth, deauth, loader]
  )

  const getJWT = useCallback(
    async () => {
      if (!accessToken) {
        return undefined
      }

      const expires = new Date(0)
      expires.setUTCSeconds(jwtDecode(accessToken).exp)
      if (expires.valueOf() > new Date().valueOf()) {
        return accessToken
      }

      if (!refreshReq) {
        refreshReq = refresh()
      }

      return refreshReq
    },
    [accessToken, refresh]
  )

  const value = {
    auth,
    deauth,
    user,
    roles,
    authed: !!user,
    getJWT,
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default Context