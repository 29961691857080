import React, { useMemo } from "react"
import styled from "styled-components"

import RawSelect from "components/RawSelect"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasErrors }) => hasErrors ? 20 : 30}px;
  width: 100%;
`

const Label = styled.label`
  margin-bottom: 10px;
  width: 100%;
`
const Errors = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.inputErrorTxtColor};
`

export default function Select({ id, label, errors = [], ...props }) {
  const hasErrors = useMemo(() =>
    errors && errors.length > 0,
  [errors])

  return (
    <Container>
      {label &&
        <Label htmlFor={id}>
          {label}
        </Label>
      }
      <RawSelect id={id} {...props}/>
      {hasErrors &&
        <Errors>
          {errors[0]}
        </Errors>
      }
    </Container>
  )
}