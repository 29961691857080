import React, { useCallback, useRef } from "react"
import styled from "styled-components"
import { Times } from "@styled-icons/fa-solid"

import ImgixImg from "components/ImgixImg"

const Placeholder = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.fileInputBgColor};
  border-radius: 58px;
  color: white;
  display: flex;
  height: 58px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 58px;
`

const Img = styled(ImgixImg)`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`

const HTMLInput = styled.input`
  position: absolute;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  height: 58px;
  border-radius: 58px;
  cursor: pointer;
`

const ClearWrapper = styled.div`
  align-items: center;
  background-color: rgba(0,0,0,.75);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  &:hover {
    opacity: 1;
  }
`

const Clear = styled(Times)`
`

export default function({ placeholderIcon: Icon, value, onChange, label, ...props }) {
  const ref = useRef()

  const loadBase64 = useCallback(e => {
    const file = e.target.files[0]

    if ( /\.(jpe?g|png|gif)$/i.test(file.name) ) {
      const reader = new FileReader()

      reader.addEventListener("load", function() {
        onChange(this.result)
      }, false)

      reader.readAsDataURL(file)
    }
  }, [onChange])

  const clear = useCallback(() => {
    onChange(null)
    ref.current.value = ""
  }, [onChange])

  return (
    <Placeholder>
      {value ?
        <Img src={value}/> : <Icon size="24"/>
      }
      {value &&
        <ClearWrapper onClick={clear}>
          <Clear size="24"/>
        </ClearWrapper>
      }
      <HTMLInput
        type="file"
        ref={ref}
        onChange={loadBase64}
      />
    </Placeholder>
  )
}
