import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

import useAuthCtx from "hooks/useAuthCtx"
import Login from "routes/Login"
import LoggedIn from "routes/LoggedIn"

export default function() {
  const { authed } = useAuthCtx()

  return (
    <Switch>
      <Route path="/login" component={Login}/>
      <Route render={() =>
        authed ? <LoggedIn/> : <Redirect to='/login'/>
      }/>
    </Switch>
  )
}