import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "routes/LoggedIn/routes/Contests/routes/List"
import New from "routes/LoggedIn/routes/Contests/routes/New"
import Detail from "routes/LoggedIn/routes/Contests/routes/Detail"
import Edit from "routes/LoggedIn/routes/Contests/routes/Edit"

export default function() {
  return (
    <Switch>
      <Route exact path="/contests/new" component={New}/>
      <Route exact path="/contests/:contestId" component={Detail}/>
      <Route exact path="/contests/:contestId/edit" component={Edit}/>
      <Route exact path="/contests" component={List}/>
    </Switch>
  )
}