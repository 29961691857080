import React, { useMemo } from "react"
import styled from "styled-components"
import { Box } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import CommunitySelect from "components/CommunitySelect"
import Button from "components/Button"
import Input from "components/Input"
import FileInput from "components/FileInput"
import Select from "components/Select"
import TextArea from "components/TextArea"
import useForm from "hooks/useForm"
import useApi from "hooks/useApi"
import useAppCtx from "hooks/useAppCtx"
import contestsApi from "datasources/contests"
import uploadsApi from "datasources/uploads"
import tagsApi from "datasources/tags"
import prizesApi from "datasources/prizes"
import usersApi from "datasources/users"
import { CONTEST_KIND } from "routes/LoggedIn/routes/Contests/modules/constants"

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const kindOptions = [
  { id: CONTEST_KIND.NORMAL, name: 'Normal' },
  { id: CONTEST_KIND.RETAILER_REVIEW, name: 'Review' },
]

const constraints = {

}

function isBase64(s) {
  return s ? s.indexOf("data:") > -1 : false
}

export default function({ defaults, history }) {
  const ctx = useAppCtx()

  const { data: prizes, isFetching: isFetchingPrizes } = useApi(prizesApi.list, { perPage: 100 })
  const { data: sponsors, isFetching: isFetchingSponsors } = useApi(usersApi.listPartners)

  const {
    fields,
    values,
    setAllErrors,
    onSubmit,
  } = useForm({
    constraints,
    defaults,
  })

  const prizeOptions = useMemo(() => {
    if (prizes && prizes.length > 0) {
      return prizes.map(({ id, label }) => ({ id, name: label }))
    }
    return []
  }, [prizes])

  const sponsorOptions = useMemo(() => {
    if (sponsors && sponsors.length > 0) {
      return [{ id: null, name: "" }, ...sponsors.map(({ id, username }) => ({ id, name: username }))]
    }
    return []
  }, [sponsors])

  async function submitClicked()  {
    try {
      let contest = { ...values }

      const labels = contest.tags.split(",").map(x => x.trim())
      const { data: dbTags } = await tagsApi.getMultiple({ ctx, labels, communityId: contest.communityId })
      contest.tags = dbTags.map(x => x.id)
      contest.sponsoredBy = contest.sponsoredBy !== "" ? contest.sponsoredBy : null
      contest.startsAt = contest.startsAt+"T14:00:00.000Z"
      contest.endsAt = contest.endsAt+"T14:00:00.000Z"

      if (isBase64(contest.featuredImageUrl)) {
        const path = `contests/${!contest.id ? "ownerId/image" : `${contest.id}/image`}`
        const base64 = contest.featuredImageUrl
        contest.featuredImageUrl = await uploadsApi.post(ctx, { path, base64, orphan: !contest.id })
      }

      if (contest.id) {
        await contestsApi.patch(ctx, contest)
      } else {
        await contestsApi.post(ctx, contest)
      }

      history.push("/contests")
    } catch(e) {
      setAllErrors(e)
    }
  }

  return (
    <PageContainer onBack={() => history.push("/contests")}>
      <HTMLForm onSubmit={onSubmit(submitClicked)}>
        <Input label="Title" {...fields.title}/>
        <TextArea rows={5} label="Description" {...fields.description}/>
        <Select label="Kind" options={kindOptions} {...fields.kind}/>
        <CommunitySelect field={fields.communityId}/>
        <Input label="Tags" {...fields.tags}/>
        <Input label="Max Winners" {...fields.maxWinners}/>
        <FileInput label="Image" placeholderIcon={Box} {...fields.featuredImageUrl} />
        {!isFetchingPrizes && <Select label="Prize" options={prizeOptions} {...fields.prizeId}/>}
        {!isFetchingSponsors && <Select label="Sponsor" options={sponsorOptions} {...fields.sponsoredBy}/>}
        <Input label="Starts At" {...fields.startsAt}/>
        <Input label="Ends At" {...fields.endsAt}/>
        <Button.Flex>
          Save
        </Button.Flex>
      </HTMLForm>
    </PageContainer>
  )
}
