import React from "react"

function isImgixPath(s) {
  return /(development|production):/.test(s)
}

function getImgixPath(fullPath) {
  if (isImgixPath(fullPath)) {
    const [env, path] = fullPath.split(":")
    return `https://daily-bonfire-${env}.imgix.net${path}`
  }
  return fullPath
}

export default function({ src, alt, ...props }) {
  return (
    <img src={getImgixPath(src)} alt={alt} {...props}/>
  )
}