import { useCallback } from "react"

export default function useClipboard() {
  const copyToClipboard = useCallback(
    (text) => () => {
      let tempInput = document.createElement("input")
      tempInput.type = "text"
      tempInput.value = text

      document.body.appendChild(tempInput)

      tempInput.select()
      document.execCommand("Copy")

      document.body.removeChild(tempInput)
    },
    []
  )

  return { copyToClipboard }
}
