import React, { useCallback } from "react"
import styled from "styled-components"
import { format } from "date-fns"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import Button from "components/Button"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import prizesApi from "datasources/prizes"
import { Plus, Heart, Box, Dice, CalendarAlt, TimesCircle, ArrowCircleRight, Star } from "@styled-icons/fa-solid"
import useAppCtx from "hooks/useAppCtx"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px 40px;
  margin-bottom: 30px;
`

const filterOptions = {
  regularPrize: {
    label: "Regular Prizes",
    queryKey: "prizeTypes",
    queryValue: "item"
  },
  fabulousPrize: {
    label: "Fabulous Prizes",
    queryKey: "prizeTypes",
    queryValue: "fabulous"
  },
  extraLives: {
    label: "Extra Lives",
    queryKey: "prizeTypes",
    queryValue: "life"
  },
}

const sortOptions = {
  label: "Label",
  createdAt: "Created At",
  stock: "Stock",
  prizeType: "Prize Type",
  maxRoll: "Likelyhood",
}

export default function({ history }) {
  const ctx = useAppCtx()
  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "prizesList",
  })

  const { data: prizes, isFetching, setData } = useApi(prizesApi.list, apiProps)

  const onDelete = useCallback(async id => {
    if (window.confirm("Are you sure you want to delete this prize?")) {
      await prizesApi.del(ctx, id)
      setData(
        prizes.filter(x =>
          x.id !== id
        )
      )
    }
  },[ctx, prizes, setData])

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
        <Button.Short altStyle icon={Plus} onClick={() => history.push("/prizes/new")}/>
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {prizes.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Icon
                icon={Star}
                alt={"Fabulous Prize"}
                visible={x.prizeType === "fabulous"}
              />
              <Table.Cell.Image
                imageUrl={x.imageUrl}
                fallbackIcon={x.prizeType === "life" ? Heart : Box}
              />
              <Table.Cell.Text
                text={x.label}
              />
              <Table.Cell.IconText
                width={100}
                icon={x.prizeType === "life" ? Heart : Box}
                text={x.stock}
                alt={x.prizeType === "life" ? "Lives" : "Current Stock"}
              />
              <Table.Cell.IconText
                width={100}
                icon={Dice}
                text={x.maxRoll}
                alt="Likelyhood"
              />
              <Table.Cell.IconText
                icon={CalendarAlt}
                alt="Created At"
                width={180}
                text={format(new Date(x.createdAt), "MM/dd/yyyy'")}
              />
              <Table.Cell.Icon
                icon={TimesCircle}
                alt="Delete"
                onClick={() => onDelete(x.id)}
              />
              <Table.Cell.IconLink
                icon={ArrowCircleRight}
                alt="Edit"
                to={`/prizes/${x.id}`}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
