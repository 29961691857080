import { useState, useCallback } from "react"

export default function(initialState = []) {
  const [data, setData] = useState(new Set(initialState))

  const replace = useCallback(v =>
    setData(new Set(v)),
  [])

  const add = useCallback(v =>
    data.add(v) && setData(data),
  [data])

  const remove = useCallback(v =>
    data.delete(v) && setData(data),
  [data])

  const clear = useCallback(() =>
    setData(new Set()),
  [])

  return [data, { add, remove, replace, clear }]
}