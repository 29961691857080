import { get, patch, post, put, del, buildUrl } from "datasources/common"

export default {
  async list(ctx, { page, perPage, sort, query, filters = {} }) {
    const params = {
      page,
      perPage,
      sort,
      ...(query !== "" ? { query } : {}),
      ...filters,
    }

    const { data, headers } = await get(ctx, buildUrl("/golive-streams/contests", params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
  async post(ctx, contest) {
    return await post(ctx, buildUrl(`/golive-streams/contests`), { body: contest })
  },
  async get(ctx, id) {
    return await get(ctx, buildUrl(`/golive-streams/contests/${id}`))
  },
  async patch(ctx, contest) {
    return await patch(ctx, buildUrl(`/golive-streams/contests/${contest.id}`), { body: contest })
  },
  async del(ctx, id) {
    return await del(ctx, buildUrl(`/golive-streams/contests/${id}`))
  },
  async getStreams(ctx, id, { page, perPage }) {
    const params = {
      page,
      perPage,
    }

    return await get(ctx, buildUrl(`/golive-streams/contests/${id}/streams`, params))
  },
  async getWinners(ctx, id) {
    return await get(ctx, buildUrl(`/golive-streams/contests/${id}/winners`))
  },
  async preselectStream(ctx, { id, streamId, isPreSelected }) {
    return await patch(ctx, buildUrl(`/golive-streams/contests/${id}/streams/${streamId}/pre-selected`), { body: { isPreSelected }})
  },
  async pickWinner(ctx, { id, streamId }) {
    return await put(ctx, buildUrl(`/golive-streams/contests/${id}/winners/${streamId}`))
  }
}
