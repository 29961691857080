import React from "react"
import { Link } from "react-router-dom"

import Cell from "components/Table/components/Cell"

function CellLink({ text, to, alt, }) {
  return (
    <Cell >
      <Link to={to} alt={alt}>
        {text}
      </Link>
    </Cell>
  )
}

CellLink.displayName = "Cell.Link"

export default CellLink