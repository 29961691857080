import { useReducer, useCallback } from "react"
import validate from "validate.js"

import useSet from "hooks/useSet"

function reducer(state, action) {
  switch (action.type) {
    case "change":
      return { ...state, values: { ...state.values, ...action.payload }}
    case "setAllErrors":
      return { ...state, errors: action.payload }
    case "setErrors":
      return {  ...state, errors: {...state.errors, ...action.payload }}
    case "addError":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: [
            ...(state.errors[action.payload.field] || []),
            action.payload.message,
          ],
        },
      }
    default:
      return state
  }
}

function setToObj(set){
  return [...set].reduce((acc, curr) =>
    ({ ...acc, [curr]: true })
  , {})
}

const defaultOptions = {}
validate.validators.presence.options = { message: "is required." }
validate.capitalize = (str) =>
  str.split(" ").map(v =>
    validate.isString(v) ?
      v[0].toUpperCase() + v.slice(1) :
      v
  ).join(" ")

export default function({ defaults = {}, constraints = {} } = {}) {
  const initialState = { values: defaults, errors: {} }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [dirty, dirtyOps] = useSet()
  const [touched, touchedOps] = useSet()

  const { errors, values } = state

  const setAllErrors = useCallback(payload =>
    dispatch({ type: "setAllErrors", payload }),
  [])

  const setErrors = useCallback(payload =>
    dispatch({ type: "setErrors", payload }),
  [])

  const addError = useCallback((field, message) =>
    dispatch({ type: "addError", payload: { field, message } }),
  [])

  const change = useCallback(payload =>
    dispatch({ type: "change", payload }),
  [])

  const _validateField = useCallback((k, values) => {
    const validateErrors = validate(
      values,
      { [k]: constraints[k] },
      defaultOptions
    )
    setErrors({ [k]: validateErrors ? validateErrors[k] : null })
    return !validateErrors
  }, [constraints, setErrors])

  const onChange = useCallback(k => e => {
    const v = e && e.target ? e.target.value : e
    v === initialState[k] ? dirtyOps.remove(k) : dirtyOps.add(k)
    touchedOps.add(k)
    _validateField(k, { ...values, [k]: v })
    change({ [k]: v })
  }, [change, dirtyOps, initialState, touchedOps, values, _validateField])

  const validateField = useCallback(k => {
    touchedOps.add(k)
    return _validateField(k, values)
  }, [_validateField, touchedOps, values])

  const onSubmit = useCallback(fn => e => {
    e.preventDefault()
    const validateErrors = validate(values, constraints, defaultOptions)
    setAllErrors(validateErrors || {})
    !validateErrors && fn()
  }, [constraints, setAllErrors, values])

  const reset = useCallback(() => {
    change(defaults)
    setAllErrors({})
  }, [change, defaults, setAllErrors])

  const fields = Object.entries(values).reduce(
    (prev, [k, v]) => ({
      ...prev,
      [k]: {
        id: k,
        name: k,
        value: v,
        errors: errors[k],
        onChange: onChange(k),
       },
    }),
    {}
  )

  return {
    values,
    reset,
    errors,
    dirty: setToObj(dirty),
    touched: setToObj(touched),
    change,
    onChange,
    onSubmit,
    addError,
    setErrors,
    setAllErrors,
    validateField,
    fields,
  }
}