import React, { useCallback } from "react"

import Form from "routes/LoggedIn/routes/Communities/components/Form"
import useApi from "hooks/useApi"
import communitiesApi from "datasources/communities"

function transformCommunity(apiCommunity) {
  const {
    config: {
      trendingEnabled = true,
      trendingSeoDescription = "",
      hashtagSeoDescription = "",
      retailerSeoDescription = "",
      googleMapsKeyword = "",
      ageGate = null,
      landingImageUrl = "",
      appIconUrl = "",
      landingBackgroundColor = ""
    },
    ...community
  } = apiCommunity

  return {
    ...community,
    trendingEnabled,
    trendingSeoDescription,
    hashtagSeoDescription,
    retailerSeoDescription,
    googleMapsKeyword,
    ageGate,
    landingImageUrl,
    appIconUrl,
    landingBackgroundColor,
  }
}

export default function({ match, history }) {
  const { params: { communityId } } = match

  const apiGet = useCallback(ctx => {
    return communitiesApi.get(ctx, communityId)
  }, [communityId])

  const {
    data: community,
    isFetching
  } = useApi(apiGet)

  return (
    !isFetching && <Form defaults={transformCommunity(community)} history={history}/>
  )
}