import React, { useMemo } from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasErrors }) => hasErrors ? 20 : 30}px;
  width: 100%;
`

const Label = styled.label`
  margin-bottom: 10px;
  width: 100%;
`

const HTMLTextArea = styled.textarea`
  background: none;
  border: none;
  border-bottom: solid 2px ${({ theme }) => theme.txtAreaBorderColor };
  color: ${({ theme }) => theme.txtAreaTxtColor};
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  outline: none;
  padding: 0;
  padding-bottom: 10px;
  width: 100%;
  height: auto;
  resize: none;
  &:focus {
    border-bottom: solid 2px ${({ theme }) => theme.txtAreaFocusBorderColor };
    color: ${({ theme }) => theme.txtAreaFocusTxtColor };
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.txtAreaFocusTxtColor };
    caret-color: white;
  }
`

export default function({ id, label, value, onChange, errors = [], ...props }) {
  const hasErrors = useMemo(() =>
    errors && errors.length > 0,
  [errors])

  return (
    <Container hasErrors={hasErrors}>
      {label &&
        <Label htmlFor={id}>
          {label}
        </Label>
      }
      <HTMLTextArea
        id={id}
        value={value || ""}
        onChange={onChange}
        {...props}
      />
    </Container>
  )
}
