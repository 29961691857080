import { useState, useEffect } from "react"
import useAppCtx from "./useAppCtx"

export default function(api, { page, perPage, filters = {}, sort, query, setTotalCount = () => {} } = {}) {
  const ctx = useAppCtx()
  const [isFetching, setFetching] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        setFetching(true)
        const { data, total } = await api(ctx, { page, perPage, sort, filters, query })
        setTotalCount(total)
        setData(data || null)
        setFetching(false)
      } catch (error) {
        setError(error)
        setFetching(false)
      }
    })()
  // eslint-disable-next-line
  }, [api, page, perPage, JSON.stringify(filters), query, sort])

  return {
    data,
    setData,
    error,
    isFetching,
  }
}