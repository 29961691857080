import React, { useCallback } from "react"

import Form from "routes/LoggedIn/routes/Contests/components/Form"
import useApi from "hooks/useApi"
import contestsApi from "datasources/contests"

function transformContest(apiContest) {
  const {
    prize,
    sponsoredBy,
    tags,
    startsAt,
    endsAt,
    ...contest
  } = apiContest

  return {
    prizeId: prize && prize.id,
    sponsoredBy: sponsoredBy && sponsoredBy.id,
    tags: tags.map(x => x.label).join(","),
    startsAt: startsAt.substring(0, 10),
    endsAt: endsAt.substring(0, 10),
    ...contest,
  }
}

export default function({ match, history }) {
  const { params: { contestId } } = match

  const apiGet = useCallback(ctx => {
    return contestsApi.get(ctx, contestId)
  }, [contestId])

  const {
    data: contest,
    isFetching
  } = useApi(apiGet)

  return (
    !isFetching && <Form defaults={transformContest(contest)} history={history}/>
  )
}