import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "routes/LoggedIn/routes/Users/routes/List"
import Detail from "routes/LoggedIn/routes/Users/routes/Detail"

export default function() {
  return (
    <Switch>
      <Route exact path="/users/:userId" component={Detail}/>
      <Route path="/users" component={List}/>
    </Switch>
  )
}