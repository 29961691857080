import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { User } from '@styled-icons/fa-solid'

import PageContainer from 'components/PageContainer'
import Button from 'components/Button'
import Avatar from 'components/Avatar'
import Input from 'components/Input'
import CommunitySelect from 'components/CommunitySelect'
import PhoneInput from 'components/PhoneInput'
import FileInput from 'components/FileInput'
import Select from 'components/Select'
import TextArea from 'components/TextArea'
import usersApi from 'datasources/users'
import shadowBansApi from 'datasources/shadowBans'
import uploadsApi from 'datasources/uploads'
import useAppCtx from 'hooks/useAppCtx'
import useApi from 'hooks/useApi'
import useForm from 'hooks/useForm'
import ToggleDropdown from 'components/ToggleDropdown'

const Banner = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.formBgColor};
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 96px auto 100px 140px;
  padding: 30px;
`

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 490px;
  padding: 30px;
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  max-width: 720px
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
`

const constraints = {
  username: { presence: { allowEmpty: false } },
  phone: function (_, attrs) {
    if (!attrs.email) {
      return { presence: { allowEmpty: false } }
    }
  },
  email: function (_, attrs) {
    if (!attrs.phone) {
      return { presence: { allowEmpty: false } }
    }
  },
}

const roles = [
  { id: 'basic', name: 'Customer' },
  { id: 'admin', name: 'Admin' },
  { id: 'triviaAdmin', name: 'Trivia Admin' },
]

const isPartnerOpts = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]

const convertToBoolean = (val) =>
  val === 'true' ? true : val === 'false' ? false : val

function isBase64(s) {
  return s ? s.indexOf('data:') > -1 : false
}

function Form({ user, onSuccess }) {
  const ctx = useAppCtx()

  const { triviaAdmin } = ctx.auth.roles
  const isCurrentUser = ctx.auth.user.id === user.id
  const canChangeRole = triviaAdmin && !isCurrentUser

  const { fields, values, setAllErrors, onSubmit } = useForm({
    constraints,
    defaults: user,
  })

  const isPartnerBooleanValue = convertToBoolean(values.isPartner)

  async function submitClicked() {
    try {
      let {
        id,
        role,
        username,
        title,
        description,
        phone,
        email,
        avatarUrl,
        goliveTag,
        partnerCommunityId,
      } = values

      if (isBase64(avatarUrl)) {
        const path = `user/${id}/avatar`
        const base64 = avatarUrl
        avatarUrl = await uploadsApi.post(ctx, { path, base64 })
      }

      const tagInfo = goliveTag ? { goliveTag } : { goliveTagId: null }

      await usersApi.patchData(ctx, {
        id,
        username,
        title,
        description,
        phone,
        email,
        avatarUrl,
        ...tagInfo,
        partnerCommunityId,
        isPartner: isPartnerBooleanValue,
      })

      if (canChangeRole) {
        await usersApi.putRole(ctx, { id, role, originalRole: user.role })
      }

      onSuccess(values)
    } catch (e) {
      setAllErrors(e)
    }
  }

  return (
    <HTMLForm onSubmit={onSubmit(submitClicked)}>
      <Input label='Username' autoFocus {...fields.username} />
      <Input label='Title' autoFocus {...fields.title} />
      <TextArea
        rows={5}
        label='Description'
        autoFocus
        {...fields.description}
      />
      <PhoneInput label='Phone' {...fields.phone} />
      <Input label='E-mail' {...fields.email} />
      {canChangeRole && (
        <Select label='Role' options={roles} {...fields.role} />
      )}
      <FileInput label='Avatar' placeholderIcon={User} {...fields.avatarUrl} />
      <Input
        label='Tag (without # and spaces)'
        autoFocus
        {...fields.goliveTag}
      />
      <Select
        label='Is Partner?'
        options={isPartnerOpts}
        {...fields.isPartner}
      />
      {isPartnerBooleanValue && (
        <CommunitySelect field={fields.partnerCommunityId} />
      )}
      <Button.Flex>Save</Button.Flex>
    </HTMLForm>
  )
}

const BAN_REASONS = {
  Cheating: 'Cheating',
  'Outside US': 'Outside US',
}

const transformUser = (user) => {
  if (!user) {
    return
  }

  const newUser = { ...user, partnerCommunityId: user.partnerCommunityId || '' }
  newUser.goliveTag = (user.goliveTag || {}).label || ''
  const { roles } = newUser
  delete newUser.roles

  if (roles.includes('triviaAdmin')) {
    newUser.role = 'triviaAdmin'
  } else if (roles.includes('admin')) {
    newUser.role = 'admin'
  } else {
    newUser.role = 'basic'
  }

  return newUser
}

export default function ({ match, history }) {
  const ctx = useAppCtx()

  const {
    params: { userId },
  } = match

  const apiGet = useCallback(
    (ctx) => {
      return usersApi.get(ctx, userId)
    },
    [userId]
  )

  const { data: user, setData, isFetching } = useApi(apiGet)

  const shadowBansApiGet = useCallback(
    (ctx) => {
      return shadowBansApi.get(ctx, userId)
    },
    [userId]
  )

  const { data: shadowBans, isFetching: isFetchingShadowBans } = useApi(
    shadowBansApiGet
  )

  const setDataForForm = useCallback(
    (user) => {
      setData(transformUser(user))
    },
    [setData]
  )

  const userForForm = useMemo(() => transformUser(user), [user])

  async function shadowBanClicked() {
    if (
      window.confirm(
        'Are you sure you want to shadow ban this user? This action cannot be undone.'
      )
    ) {
      await shadowBansApi.post(ctx, userId)
      window.location.reload()
    }
  }

  async function banClicked(banReason) {
    if (
      window.confirm(
        'Are you sure you want to ban this user? This action cannot be undone.'
      )
    ) {
      await usersApi.ban(ctx, { id: userId, banReason })
      window.location.reload()
    }
  }

  async function creatorInvite() {
    if (window.confirm('Are you sure you want to invite this user?')) {
      await usersApi.patch(ctx, {
        id: userId,
        creatorStatus: 'INVITED',
        resendCreatorProgramInvitation: true,
      })
      window.location.reload()
    }
  }

  async function creatorPromote() {
    if (
      window.confirm('Are you sure you want to promote this user to creator?')
    ) {
      await usersApi.patch(ctx, { id: userId, creatorStatus: 'CREATOR' })
      window.location.reload()
    }
  }

  return (
    !isFetching && (
      <PageContainer onBack={() => history.goBack()}>
        <Banner>
          <Avatar avatarUrl={user.avatarUrl} />
          {user.username} {user.banReason && `// Banned for ${user.banReason}`}
        </Banner>
        <ActionsWrapper>
          {user.creatorStatus === 'NOT_CREATOR' && (
            <Button.Flex altStyle={true} onClick={creatorInvite}>
              Invite Creator
            </Button.Flex>
          )}
          {user.creatorStatus === 'INVITED' && (
            <Button.Flex altStyle={true} onClick={creatorInvite}>
              Reinvite Creator
            </Button.Flex>
          )}
          {user.creatorStatus === 'INVITED' && (
            <Button.Flex altStyle={true} onClick={creatorPromote}>
              Promote Creator
            </Button.Flex>
          )}
          {user.creatorStatus === 'CREATOR' && <div>Creator Program</div>}
          {!isFetchingShadowBans && shadowBans.length !== 0 && (
            <div>Shadow Banned</div>
          )}
          {!isFetchingShadowBans && shadowBans.length === 0 && (
            <Button.Flex onClick={shadowBanClicked}>Shadow Ban</Button.Flex>
          )}
          {!user.bannedAt && (
            <ToggleDropdown
              buttonAltStyle={false}
              label='Ban'
              options={BAN_REASONS}
              selectedOptions={[]}
              onSelect={banClicked}
            />
          )}
        </ActionsWrapper>
        <BoxContainer>
          <Form user={userForForm} onSuccess={setDataForForm} />
        </BoxContainer>
      </PageContainer>
    )
  )
}
