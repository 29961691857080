import React from "react"

import Form from "routes/LoggedIn/routes/Questions/components/Form"

const defaults = {
  batch: "",
  title: "",
  correctAnswer: "",
  incorrectAnswerA: "",
  incorrectAnswerB: "",
  hostNotes: "",
  tag: "",
}

export default function({ history }) {
  return (
    <Form defaults={defaults} history={history}/>
  )
}