import React, { useCallback } from 'react'

import Form from 'routes/LoggedIn/routes/Products/components/Form'
import useApi from 'hooks/useApi'
import productsApi from 'datasources/products'

function transformProduct(apiProduct) {
  const {
    category,
    partner,
    states,
    priceCents,
    metadata,
    ...product
  } = apiProduct

  return {
    communityId: partner && partner.partnerCommunityId,
    categoryId: category && category.id,
    partnerId: partner && partner.id,
    price: (priceCents / 100).toFixed(2),
    state: states.map((x) => x.iso)[0] || '',
    ...product,
    metadata: (metadata || {}).fields || [],
  }
}

export default function ({ match, history }) {
  const {
    params: { productId },
  } = match

  const apiGet = useCallback(
    (ctx) => {
      return productsApi.get(ctx, productId)
    },
    [productId]
  )

  const { data: product, isFetching } = useApi(apiGet)

  return (
    !isFetching && (
      <Form defaults={transformProduct(product)} history={history} />
    )
  )
}
