import React from "react"
import styled from "styled-components"
import { ArrowLeft, ArrowRight } from "@styled-icons/fa-solid"

import ToggleDropdown from "components/ToggleDropdown"
import Button from "components/Button"

const Container = styled.div`
  display: grid;
  flex-grow: 0;
  grid-gap: 20px;
  grid-template-columns: 40px 40px 40px;
`

const PER_PAGE_OPTIONS = {
  10: "10" ,
  25: "25" ,
  50: "50" ,
  100: "100" ,
}

export default function({ onNext = () => {}, onPrev = () => {}, perPage, onPerPageChanged = () => {} }) {
  return (
    <Container>
      <Button.Short altStyle icon={ArrowLeft} onClick={onPrev}/>
      <ToggleDropdown
        options={PER_PAGE_OPTIONS}
        selectedOptions={[PER_PAGE_OPTIONS[perPage]]}
        onSelect={onPerPageChanged}
      />
      <Button.Short altStyle icon={ArrowRight} onClick={onNext}/>
    </Container>
  )
}


