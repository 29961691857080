import { get, put, post, buildUrl } from "datasources/common"

const list = async (ctx, { page, perPage, sort, query, filters = {} }) => {
  const params = {
    page,
    perPage,
    ...(sort !== "" ? { sort } : {}),
    ...(query !== "" ? { query } : {}),
    ...filters,
  }

  const { data, headers } = await get(ctx, buildUrl("/communities", params))
  const total = parseInt(headers['x-total-count'])
  return { data, total }
}

export default {
  list,
  async post(ctx, community) {
    return await post(ctx, buildUrl(`/communities`), { body: community })
  },
  async get(ctx, id) {
    return await get(ctx, buildUrl(`/communities/${id}`))
  },
  async put(ctx, community) {
    return await put(ctx, buildUrl(`/communities/${community.id}`), { body: community })
  },
  async getFilterOptions(ctx) {
    const { data: communities } = await list(ctx, { perPage: 50, page: 1, filters: { published: true } })

    const data = communities?.reduce((acc, community) => ({
      ...acc,
      [community.slug]: {
        label: `${community.name} Community`,
        queryKey: 'communityId',
        queryValue: community.id,
        removes: communities.filter(({ id }) => community.id !== id ).map(({ slug }) => slug)
      },
    }), {})

    return { data }
  }
}