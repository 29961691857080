import { get, put, post, del, buildUrl } from "datasources/common"

export default {
  async list(ctx, { page, perPage, sort, query, filters = {} }) {
    const params = {
      page,
      perPage,
      sort,
      ...(query !== "" ? { query } : {}),
      ...filters,
      includesGolive: true,
    }

    const { data, headers } = await get(ctx, buildUrl("/golive-schedules", params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
  async get(ctx, id) {
    return await get(ctx, buildUrl(`/golive-schedules/${id}`))
  },
  async post(ctx, events) {
    if (events.scheduledTo === "") {
      events.scheduledTo = null
    }
    return await post(ctx, buildUrl(`/golive-schedules`), { body: events })
  },
  async put(ctx, events) {
    return await put(ctx, buildUrl(`/golive-schedules/${events.id}`), { body: events })
  },
  async del(ctx, id) {
    return await del(ctx, buildUrl(`/golive-schedules/${id}`))
  },
}