import React from "react"
import styled from "styled-components"
import { User } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Button from "components/Button"
import CommunitySelect from "components/CommunitySelect"
import Input from "components/Input"
import FileInput from "components/FileInput"
import TextArea from "components/TextArea"
import usersApi from "datasources/users"
import uploadsApi from "datasources/uploads"
import useAppCtx from "hooks/useAppCtx"
import useForm from "hooks/useForm"

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const constraints = {}

function isBase64(s) {
  return s ? s.indexOf("data:") > -1 : false
}

function Form({ user, history }) {
  const ctx = useAppCtx()

  const { fields, values, setAllErrors, onSubmit } = useForm({
    constraints,
    defaults: user
  })

  async function submitClicked() {
    try {
      let { username, title, description, avatarUrl, website, instagramUsername, goliveTag, partnerCommunityId } = values

      if (isBase64(avatarUrl)) {
        const path = `user/ownerId/avatar`
        const base64 = avatarUrl
        avatarUrl = await uploadsApi.post(ctx, { path, base64, orpha: true })
      }

      const { data: user } = await usersApi.postPartner(ctx, { username, title, description, avatarUrl, website, instagramUsername, goliveTag, partnerCommunityId })
      history.push(`/users/${user.id}`)
    } catch (e) {
      setAllErrors(e)
    }
  }

  return (
    <HTMLForm onSubmit={onSubmit(submitClicked)}>
      <Input label="Username" autoFocus {...fields.username} />
      <Input label="Title" {...fields.title} />
      <TextArea rows={5} label="Description" {...fields.description} />
      <Input label="Instagram Username" {...fields.instagramUsername} />
      <Input label="Website" {...fields.website} />
      <FileInput label="Avatar" placeholderIcon={User} {...fields.avatarUrl} />
      <CommunitySelect field={fields.partnerCommunityId}/>
      <Input label="Tag (without # and spaces)" {...fields.goliveTag} />
      <Button.Flex>Save</Button.Flex>
    </HTMLForm>
  )
}

const defaults = {
  username: "",
  title: "",
  description: "",
  avatarUrl: "",
  website: "",
  instagramUsername: "",
  goliveTag: "",
  partnerCommunityId: "",
}


export default function({ match, history }) {
  return (
    <PageContainer onBack={() => history.goBack()}>
      <Form user={defaults} history={history} />
    </PageContainer>
  )
}
