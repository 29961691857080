import React, { useCallback } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import {
  CalendarAlt,
  Copy,
  User,
  Ban,
  ArrowCircleRight,
  Trophy,
  Medal,
} from '@styled-icons/fa-solid'
import { CheckSquare, Square } from '@styled-icons/fa-regular'

import PageContainer from 'components/PageContainer'
import Table from 'components/Table'
import Pager from 'components/Pager'
import SearchInput from 'components/SearchInput'
import ToggleDropdown from 'components/ToggleDropdown'
import BadgeList from 'components/BadgeList'
import useApi from 'hooks/useApi'
import useList from 'hooks/useList'
import useAppCtx from 'hooks/useAppCtx'
import useClipboard from 'hooks/useClipboard'
import transactionsApi from 'datasources/transactions'

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px;
  margin-bottom: 30px;
`

const filterOptions = {
  isBanned: {
    label: 'Banned',
    queryKey: 'isBanned',
    queryValue: true,
  },
  notBanned: {
    label: 'Not Banned',
    queryKey: 'isBanned',
    queryValue: false,
  },
  pending: {
    label: 'Pending',
    queryKey: 'status',
    queryValue: 'pending',
    removes: ['earning', 'rejected', 'completed'],
  },
  completed: {
    label: 'Completed',
    queryKey: 'status',
    queryValue: 'completed',
    removes: ['pending', 'rejected'],
  },
  rejected: {
    label: 'Rejected',
    queryKey: 'status',
    queryValue: 'rejected',
    removes: ['earning', 'completed', 'pending'],
  },
  cashout: {
    label: 'Cashout',
    queryKey: 'kind',
    queryValue: 'cashout',
    removes: ['earning'],
  },
  earning: {
    label: 'Earning',
    queryKey: 'kind',
    queryValue: 'earning',
    removes: ['cashout', 'pending'],
  },
}

const defaultFilters = ['pending', 'cashout']

const sortOptions = {
  createdAt: 'Created At',
  username: 'Username',
  amountCents: 'Amount',
  winCount: 'Wins',
  totalEarnedCents: 'Total Earnings',
}

// const statusMap = {
//   pending: "Pending",
//   completed: "Completed"
// }

export default function () {
  const ctx = useAppCtx()

  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps,
  } = useList({
    filterOptions,
    defaultFilters,
    sortOptions,
    cacheKey: 'transactionsList',
  })

  const { copyToClipboard } = useClipboard()

  const { data: transactions, isFetching, setData } = useApi(
    transactionsApi.list,
    apiProps
  )

  const onComplete = useCallback(
    async ({ id, userId }) => {
      if (
        window.confirm(
          'Are you sure you want to mark this transaction as completed?'
        )
      ) {
        await transactionsApi.patch(ctx, id, userId)
        setData(
          transactions.map((x) =>
            x.id === id ? { ...x, status: 'completed' } : x
          )
        )
      }
    },
    [ctx, transactions, setData]
  )

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {transactions.map((x) => (
            <Table.Row key={x.id}>
              <Table.Cell.Icon
                icon={x.status === 'pending' ? Square : CheckSquare}
                alt='Completed?'
                onClick={
                  x.status === 'pending'
                    ? () => onComplete({ id: x.id, userId: x.userId })
                    : null
                }
              />
              <Table.Cell.Image
                to={`/users/${x.id}`}
                imageUrl={!x.bannedAt && x.avatarUrl}
                fallbackIcon={!x.bannedAt ? User : Ban}
                alt={!x.bannedAt ? x.username : `Banned: ${x.banReason}`}
              />
              <Table.Cell.Link
                to={`/users/${x.userId}`}
                text={x.username}
                width={220}
              />
              <Table.Cell.IconText
                icon={Copy}
                alt={x.paypalEmail}
                text={`${x.paypalEmail}`}
                onClick={copyToClipboard(x.paypalEmail)}
              />
              <Table.Cell.IconText
                icon={Trophy}
                alt='Wins'
                width={100}
                text={x.winCount || 0}
              />
              <Table.Cell.IconText
                icon={Medal}
                alt='Rank'
                width={140}
                text={`$${((x.totalEarnedCents || 0) / 100).toFixed(2)}`}
              />
              <Table.Cell.Text
                style={{ color: x.kind === 'earning' ? '#89C37E' : '#C4272A' }}
                text={`$${((x.amountCents || 0) / 100).toFixed(2)}`}
                width={120}
              />
              <Table.Cell.IconText
                icon={CalendarAlt}
                alt='Created At'
                width={260}
                text={format(new Date(x.createdAt), 'Pp')}
              />
              <Table.Cell.IconLink
                icon={ArrowCircleRight}
                alt='Edit User'
                to={`/users/${x.userId}`}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
