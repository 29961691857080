import React from "react"
import styled from "styled-components"
import { format } from "date-fns"
import { User, Ban, CalendarAlt, Heart, Box, MapMarkerAlt } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import checkinsApi from "datasources/checkins"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px;
  margin-bottom: 30px;
`

const filterOptions = {
  regularPrize: {
    label: "Regular Prizes",
    queryKey: "prizeTypes",
    queryValue: "item"
  },
  fabulousPrize: {
    label: "Fabulous Prizes",
    queryKey: "prizeTypes",
    queryValue: "fabulous"
  },
  extraLives: {
    label: "Extra Lives",
    queryKey: "prizeTypes",
    queryValue: "life"
  },
  banned: {
    label: "Banned",
    queryKey: "isBanned",
    queryValue: true
  },
  notBanned: {
    label: "Not Banned",
    queryKey: "isBanned",
    queryValue: false
  },
}

const sortOptions = {
  "createdAt": "Checkin Date",
  "redeemedAt": "Redeem Date",
}

export default function() {
  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "checkins",
  })

  const { data: checkins, isFetching } = useApi(checkinsApi.list, apiProps)

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {checkins.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Image
                to={`/users/${x.userId}`}
                imageUrl={!x.userBannedAt && x.userAvatarUrl}
                fallbackIcon={!x.userBannedAt ? User : Ban}
                alt={!x.userBannedAt ? x.username : `Banned: ${x.userBanReason}`}
              />
              <Table.Cell.Link
                text={x.username}
                to={`/users/${x.userId}`}
                width={250}
              />
              <Table.Cell.Image
                imageUrl={x.prizeImageUrl}
                fallbackIcon={x.prizeType === "life" ? Heart : Box}
              />
              <Table.Cell.Text
                text={x.prizeLabel}
                width={300}
              />
              <Table.Cell.IconText
                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${x.latitude},${x.longitude}`, '_blank')}
                visible={!!x.placeName}
                text={x.placeName}
                icon={MapMarkerAlt}
              />
              <Table.Cell.IconText
                icon={CalendarAlt}
                alt="Created At"
                width={250}
                text={format(new Date(x.createdAt), "Pp")}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
