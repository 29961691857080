import React, { useMemo } from "react"
import { ThemeProvider } from "styled-components"
import useThemeCtx from "hooks/useThemeCtx"

const darkTheme = {
  pageBgColor: "#08183A",
  pageTxtColor: "#FFFFFF",
  pageLinkColor: "#FFFFFF",

  formBgColor: "#152852",

  loaderBarBgColor: "#FD5E53",

  menuBgColor: "#152852",
  menuItemTxtColor: "#FFFFFF",
  menuItemBgColor: "#152852",
  menuItemSelTxtColor: "#FFFFFF",
  menuItemSelBgColor: "#FD5E53",

  buttonBgColor: "#FD5E53",
  buttonTxtColor: "#FFFFFF",

  buttonAltBgColor: "#5C81FF",
  buttonAltTxtColor: "#FFFFFF",

  labelTxtColor: "#FFFFFF",

  inputBorderColor: "#08183A",
  inputTxtColor: "#FFFFFF",
  inputFocusBorderColor: "#FD5E53",
  inputFocusTxtColor: "#FFFFFF",
  inputErrorTxtColor: "#FF5C5C",

  inputAltBorderColor: "#5C81FF",
  inputAltTxtColor: "#FFFFFF",
  inputAltFocusBorderColor: "#FD5E53",
  inputAltFocusTxtColor: "#FFFFFF",

  selectBorderColor: "#08183A",
  selectTxtColor: "#FFFFFF",
  selectFocusBorderColor: "#FD5E53",
  selectFocusTxtColor: "#FFFFFF",
  selectErrorTxtColor: "#FF5C5C",

  fileInputBgColor: "#08183A",
  fileInputBorderColor: "#08183A",

  txtAreaBorderColor: "#08183A",
  txtAreaTxtColor: "#FFFFFF",
  txtAreaFocusBorderColor: "#FD5E53",
  txtAreaFocusTxtColor: "#FFFFFF",

  tableRowBgColor: "#152852",
  tableRowTxtColor: "#FFFFFF",
  tableRowBorderColor: "#08183A",
  tableCellImageBgColor: "#08183A",
  tableCellImageIconColor:"#FFFFFF",
  tableRowHoverBgColor: "#FD5E53",
  tableRowHoverTxtColor: "#FFFFFF",

  toggleDropdownItemBgColor: "#5C81FF",
  toggleDropdownItemTxtColor: "#FFFFFF",
  toggleDropdownItemSelBgColor: "#FD5E53",
  toggleDropdownItemSelTxtColor: "#FFFFFF",
  toggleDropdownItemHoverBgColor: "#FD5E53",
  toggleDropdownItemHoverTxtColor: "#FFFFFF",

  avatarBgColor: "#08183A",
}

const lightTheme = {
  pageBgColor: "#D3D4C9",
  pageTxtColor: "#333333",
  pageLinkColor: "#333333",

  formBgColor: "#EDF0E5",

  loaderBarBgColor: "#00AFC8",

  menuBgColor: "#EDF0E5",
  menuItemTxtColor: "#333333",
  menuItemBgColor: "#EDF0E5",
  menuItemSelTxtColor: "#FFFFFF",
  menuItemSelBgColor: "#00AFC8",

  buttonBgColor: "#00AFC8",
  buttonTxtColor: "#FFFFFF",

  buttonAltBgColor: "#EDF0E5",
  buttonAltTxtColor: "#333333",

  labelTxtColor: "#FFFFFF",

  inputBorderColor: "#333333",
  inputTxtColor: "#333333",
  inputFocusBorderColor: "#00AFC8",
  inputFocusTxtColor: "#333333",
  inputErrorTxtColor: "#FF5C5C",

  inputAltBorderColor: "#333333",
  inputAltTxtColor: "#333333",
  inputAltFocusBorderColor: "#00AFC8",
  inputAltFocusTxtColor: "#333333",

  selectBorderColor: "#333333",
  selectTxtColor: "#333333",
  selectFocusBorderColor: "#00AFC8",
  selectFocusTxtColor: "#333333",
  selectErrorTxtColor: "#FF5C5C",

  fileInputBgColor: "#08183A",
  fileInputBorderColor: "#08183A",

  txtAreaBorderColor: "#333333",
  txtAreaTxtColor: "#333333",
  txtAreaFocusBorderColor: "#00AFC8",
  txtAreaFocusTxtColor: "#FFFFFF",

  tableRowBgColor: "#EDF0E5",
  tableRowTxtColor: "#333333",
  tableRowBorderColor: "#D3D4C9",
  tableCellImageBgColor: "#D3D4C9",
  tableCellImageIconColor: "#333333",
  tableRowHoverBgColor: "#85E1EB",
  tableRowHoverTxtColor: "#333333",

  toggleDropdownItemBgColor: "#85E1EB",
  toggleDropdownItemTxtColor: "#333333",
  toggleDropdownItemSelBgColor: "#333333",
  toggleDropdownItemSelTxtColor: "#85E1EB",
  toggleDropdownItemHoverBgColor: "#333333",
  toggleDropdownItemHoverTxtColor: "#85E1EB",

  avatarBgColor: "#D3D4C9",
  avatarTxtColor: "#333333",
}

const themes = {
  darkTheme,
  lightTheme,
}

export default function({ children }) {
  const { theme } = useThemeCtx()

  const themeDef = useMemo(() => {
    return themes[theme]
  }, [theme])

  return(
    <ThemeProvider theme={themeDef}>
      {children}
    </ThemeProvider>
  )
}