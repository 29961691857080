import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "routes/LoggedIn/routes/Communities/routes/List"
import New from "routes/LoggedIn/routes/Communities/routes/New"
import Edit from "routes/LoggedIn/routes/Communities/routes/Edit"

export default function() {
  return (
    <Switch>
      <Route exact path="/communities/new" component={New}/>
      <Route exact path="/communities/:communityId/edit" component={Edit}/>
      <Route exact path="/communities" component={List}/>
    </Switch>
  )
}