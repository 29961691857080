import React from "react"
import { Search } from "@styled-icons/fa-solid"

import RawInput from "components/RawInput"

export default function({ value, onChange }) {
  return (
    <RawInput
      altStyle
      clearable
      value={value}
      onChange={onChange}
      icon={Search}
    />
  )
}
