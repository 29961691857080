import React, { useMemo } from "react"
import styled from "styled-components"
import jwtDecode from "jwt-decode"

import useForm from "hooks/useForm"
import useStep from "hooks/useStep"
import useAppCtx from "hooks/useAppCtx"
import Button from "components/Button"
import Input from "components/Input"
import PhoneInput from "components/PhoneInput"
import authApi from "datasources/auth"

const Container = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  margin: 0 auto;
  max-width: 360px;
  padding: 30px;
  width: 100%;
`

const Split = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  direction: rtl;
`

const defaults = {
  phone: null,
  code: "",
}

const step0Constraints = {
  phone: { presence: { allowEmpty: false } },
}

const step1Constraints = {
  code: { presence: { allowEmpty: false } },
}

export default function({ history }) {
  const ctx = useAppCtx()

  const [step, { next, reset }] = useStep(0, { max: 1 })

  const constraints = useMemo(() =>
    step === 0 ? step0Constraints : step1Constraints
  , [step])

  const formOpts = useMemo(() =>
    ({ defaults, constraints }),
  [constraints])

  const {
    fields,
    values,
    addError,
    onSubmit,
  } = useForm(formOpts)

  async function nextClicked()  {
    const { phone } = values
    await authApi.createAuthCode(ctx, { phone })
    next()
  }

  async function submitClicked()  {
    try {
      const { phone, code } = values
      const { data } = await authApi.createToken(ctx, { phone, code })
      const { accessToken } = data
      const { roles } = jwtDecode(accessToken)
      if (roles.some(x => ["admin", "triviaAdmin"].includes(x))) {
        ctx.auth.auth(data)
        history.replace("/")
      } else {
        addError("phone", "Unauthorized.")
        reset()
      }
    } catch(e) {
      addError("phone", "Phone and code do not match.")
      reset()
    }
  }

  return (
    <Container>
      {step === 0 &&
        <form onSubmit={onSubmit(nextClicked)}>
          <PhoneInput label="Phone" autoFocus {...fields.phone}/>
          <Button.Flex>
            Continue
          </Button.Flex>
        </form>
      }
      {step === 1 &&
        <form onSubmit={onSubmit(submitClicked)}>
          <Input label="Code" autoComplete="one-time-code" autoFocus {...fields.code}/>
          <Split>
            <Button.Flex>
              Login
            </Button.Flex>
            <Button.Flex onClick={reset} altStyle>
              Back
            </Button.Flex>
          </Split>
        </form>
      }
    </Container>
  )
}