import React, { useMemo, useCallback } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"

import useApi from "hooks/useApi"
import contestsApi from "datasources/contests"
import PageContainer from "components/PageContainer"
import Stream from "components/Stream"
import useAppCtx from "hooks/useAppCtx"

const Banner = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.formBgColor};
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto 200px;
  margin-bottom: 30px;
  padding: 30px;
`

const Streams = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
`

const PrizesLeft = styled.span `
  text-align: right;
`

function Contest({ contest, streams, deleteStream, preSelectStream, winnerStream }) {
  const ctx = useAppCtx()
  const history = useHistory()

  async function preselect(streamId, isPreSelected) {
    contestsApi.preselectStream(ctx, { id: contest.id, streamId, isPreSelected })
    preSelectStream(streamId, isPreSelected)
  }

  async function pickWinner(streamId) {
    if (window.confirm("Are you sure you want to pick this as the winner?")) {
      contestsApi.pickWinner(ctx, { id: contest.id, streamId })
      winnerStream(streamId)
    }
  }

  const prizesLeft = useMemo(() => {
    return contest.maxWinners - streams.filter(x => x.winner).length
  }, [streams, contest.maxWinners])

  return (
    <PageContainer onBack={() => history.goBack()}>
      <Banner>
        {contest.title}
        <PrizesLeft>Prizes Left: {prizesLeft}</PrizesLeft>
      </Banner>
      <Streams>
        {streams.map(stream =>
          <Stream key={stream.id} stream={{...stream.goliveStream, winner: stream.winner , isPreSelected: stream.isPreSelected}} author={stream.user} onDelete={deleteStream}>
            {prizesLeft > 0 && stream.isPreSelected && !stream.winner && <Stream.MenuItem onClick={() => pickWinner(stream.goliveStream.id)}>
              Pick Winner
            </Stream.MenuItem>}
            {prizesLeft > 0 && !stream.winner && <Stream.MenuItem onClick={() => preselect(stream.goliveStream.id, !stream.isPreSelected)}>
              {!stream.isPreSelected ? "Pre-select" : "Remove pre-select"}
            </Stream.MenuItem>}
          </Stream>
        )}
      </Streams>
    </PageContainer>
  )
}


export default function({ match }) {
  const { params: { contestId } } = match

  const apiGet = useCallback(ctx => {
    return contestsApi.get(ctx, contestId)
  }, [contestId])

  const apiGetStreams = useCallback(ctx => {
    return contestsApi.getStreams(ctx, contestId, { page: 0, perPage: 100 })
  }, [contestId])

  const {
    data: contest,
    isFetching: isFetchingContest
  } = useApi(apiGet)

  const {
    data: streams,
    setData: setStreams,
    isFetching: isFetchingStreams,
  } = useApi(apiGetStreams)

  const deleteStream = useCallback(id => {
    setStreams(streams.filter(x => x.goliveStream.id !== id))
  }, [streams, setStreams])

  const preSelectStream = useCallback((id, isPreSelected) => {
    setStreams(streams.map(x => {
      if (x.goliveStream.id === id) {
        x.isPreSelected = isPreSelected
      }
      return x
    }))
  }, [streams, setStreams])

  const winnerStream = useCallback((id, isPreSelected) => {
    setStreams(streams.map(x => {
      if (x.goliveStream.id === id) {
        x.winner = true
      }
      return x
    }))
  }, [streams, setStreams])

  return (
    !isFetchingContest && !isFetchingStreams &&
      <Contest
        contest={contest}
        streams={streams}
        deleteStream={deleteStream}
        preSelectStream={preSelectStream}
        winnerStream={winnerStream}
      />
  )
}
