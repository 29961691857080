import { useState, useMemo, useCallback, useEffect } from "react"
import {
  Filter,
  SortNumericDown,
  SortNumericDownAlt,
} from "@styled-icons/fa-solid"

const ASC = "+"
const DESC = "-"
const PER_PAGE = 25

export default function({ sortOptions, filterOptions, defaultFilters = [], cacheKey }) {
  const fullCacheKey = useMemo(
    () => `dailyBonfireCache/${cacheKey}`, [cacheKey]
  )

  const initialState = useMemo(() => {
    const defaultState = {
      page: 1,
      perPage: PER_PAGE,
      filters: defaultFilters,
      query: "",
      sort: Object.keys(sortOptions)[0],
      sortDir: DESC,
    }
    if (cacheKey) {
      const cachedState = JSON.parse(
        localStorage.getItem(fullCacheKey)
      )
      if (cachedState) {
        return { ...defaultState, ...cachedState }
      }
    }
    return defaultState
  // eslint-disable-next-line
  }, [])

  const [page, setPage] = useState(initialState.page)
  const [perPage, _setPerPage] = useState(initialState.perPage)
  const [totalCount, setTotalCount] = useState(0)

  // Page
  const nextPage = useCallback(() => {
    if (perPage * page < totalCount) {
      setPage(page + 1)
    }
  }, [perPage, page, totalCount])

  const prevPage = useCallback(() => {
    if (page !== 1) {
      setPage(page - 1)
    }
  }, [page])

  const setPerPage = useCallback((v) => {
    _setPerPage(v)
    setPage(0)
  }, [])

  const pagerProps = useMemo(() => {
    return ({
      onNext: nextPage,
      onPrev: prevPage,
      onPerPageChanged: setPerPage,
      perPage,
    })
  }, [nextPage, prevPage, perPage, setPerPage])
  // Page

  // Search
  const [query, setQuery] = useState(initialState.query)

  const queryChange = useCallback((e) => {
    setQuery(e.target ? e.target.value : e)
    setPage(1)
  }, [])

  const searchProps = useMemo(() => {
    return ({
      onChange: queryChange,
      value: query,
    })
  }, [query, queryChange])
  // Search

  // Filter
  const [filters, setFilters] = useState(initialState.filters)

  const addFilter = useCallback(v => {
    if (!filters.includes(v)) {
      let newFilters = [...filters, v]
      const { removes = [] } = filterOptions[v]
      setPage(1)
      if (removes.length > 0) {
        newFilters = newFilters.filter(x => !removes.includes(x))
      }
      setFilters(newFilters)
    }
  }, [filterOptions, filters])

  const removeFilter = useCallback(v => {
    setFilters(filters.filter(x => x !== v))
    setPage(1)
  }, [filters])

  const filterDropdownProps = useMemo(() => {
    return ({
      icon: Filter,
      label: "Filter",
      options: filterOptions,
      selectedOptions: filters,
      onSelect: addFilter,
      onUnselect: removeFilter,
    })
  }, [filterOptions, addFilter, removeFilter, filters])

  const filterBadgeListProps = useMemo(() => {
    return ({
      items: filters.map(key => {
        return filterOptions[key] ? { key, label: filterOptions[key].label } : {}
      }, {}),
      onRemove: removeFilter,
    })
  }, [removeFilter, filters, filterOptions])
  // Filter

  // Sort
  const [sort, setSort] = useState(initialState.sort)
  const [sortDir, setSortDir] = useState(initialState.sortDir)

  const changeSort = useCallback(newSort => {
    setSort(newSort)
    setSortDir(DESC)
  }, [])

  const changeSortSortDir = useCallback(() =>
    setSortDir(sortDir === ASC ? DESC : ASC)
  , [sortDir])

  const sortDropdownProps = useMemo(() => {
    return ({
      options: sortOptions,
      selectedOptions: [sort],
      onSelect: changeSort,
      onUnselect: changeSortSortDir,
      icon: sortDir === ASC ? SortNumericDown : SortNumericDownAlt,
    })
  }, [sortOptions, sort, sortDir, changeSort, changeSortSortDir])
  // Sort

  const apiProps = useMemo(() => {
    return ({
      filters: filters.reduce((acc, curr) => {
        const { query, queryKey, queryValue } = filterOptions[curr] || {}
        if (query) {
          return { ...acc, ...query }
        }
        const currValue = acc[queryKey] || []
        const newValues = Array.isArray(queryValue) ? queryValue : [queryValue]
        return { ...acc, [queryKey]: [...currValue, ...newValues] }
      }, {}),
      sort: `${sortDir}${sort}`,
      page,
      perPage,
      query,
      setTotalCount,
    })
  }, [sort, sortDir, page, perPage, query, filters, filterOptions])

  useEffect(() => {
    if (cacheKey) {
      localStorage.setItem(
        fullCacheKey,
        JSON.stringify({
          page,
          perPage,
          sort,
          sortDir,
          query,
          filters,
        })
      )
    }
  }, [cacheKey, fullCacheKey, page, perPage, filters, query, sort, sortDir])

  return {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps,
  }
}
