import React, { useCallback, useMemo, useState } from "react"
import styled from "styled-components"

import RawInput from "components/RawInput"
import RawSelect from "components/RawSelect"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasErrors }) => hasErrors ? 20 : 30}px;
  width: 100%;
`

const Label = styled.label`
  margin-bottom: 10px;
  width: 100%;
`

const Errors = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.inputErrorTxtColor};
`

const Split = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 70px auto;
`

const OPTIONS = [
  { id: "+1", name: "+1" },
  { id: "+55", name: "+55" },
  { id: "+27", name: "+27" },
]

export default function({ id, label, errors = [], value, onChange, autoComplete, autoFocus }) {
  const initialValuePrefix = useMemo(() => {
    if (/\+55/.test(value)) {
      return "+55"
    }
    if (/\+27/.test(value)) {
      return "+27"
    }
    return "+1"
  // eslint-disable-next-line
  }, [])

  const [valuePrefix, setValuePrefix] = useState(initialValuePrefix)

  const valuePhone = useMemo(() => {
    return (value || "").replace(/\+(1|55|27)/, "")
  }, [value])

  const onChangePrefix = useCallback((newValue) => {
    setValuePrefix(newValue)
    if (valuePhone !== "") {
      onChange(`${newValue}${valuePhone}`)
    }
  }, [onChange, valuePhone])

  const onChangePhone = useCallback((e) => {
    const newValuePhone = e.target.value
    if (newValuePhone !== "") {
      onChange(`${valuePrefix}${newValuePhone}`)
    } else {
      onChange(null)
    }
  }, [onChange, valuePrefix])

  const hasErrors = useMemo(() =>
    errors && errors.length > 0,
  [errors])

  return (
    <Container hasErrors={hasErrors}>
      {label &&
        <Label htmlFor={id}>
          {label}
        </Label>
      }
      <Split>
        <RawSelect id={id}
          options={OPTIONS}
          value={valuePrefix}
          onChange={onChangePrefix}
        />
        <RawInput id={id}
          value={valuePhone}
          onChange={onChangePhone}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
        />
      </Split>
      {hasErrors &&
        <Errors>
          {errors[0]}
        </Errors>
      }
    </Container>
  )
}
