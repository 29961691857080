import React, { useReducer, useEffect, useRef } from "react"
import styled, { css } from "styled-components"

import useLoaderCtx from "hooks/useLoaderCtx"

const Container = styled.div`
  background-color: ${({ theme }) => theme.loaderBarBgColor};
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  transition: width .1s ease-in-out;
  ${({ progress }) => progress <= 90 && css`
    width: ${progress}%;
    visibility: visible;
  `}
  ${({ progress }) => progress === 100 && css`
    width: 90%;
    visibility: visible;
  `}
  ${({ progress, status }) => status === "idle" && progress === 100 && css`
    width: 0%;
    visibility: hidden;
  `}
`

function reducer(state, action) {
  const { progress, status } = state

  if (action === "tick") {
    return { status, progress: progress === 100 ? progress : progress + 10 }
  }
  if (action === "start") {
    return { status: "started", progress: status === "idle" ? 0 : progress }
  }
  if (action === "stop") {
    return { ...state, status: "idle" }
  }
  if (action === "clear") {
    return { ...state, progress: 0 }
  }
  return state
}

const initialState = {
  progress: 100,
  status: 'idle'
}

export default function() {
  const intervalRef = useRef()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { count } = useLoaderCtx()
  const { status, progress } = state

  useEffect(() => {
    if (count === 0 && status === "started") {
      dispatch("stop")
    }

    if (count === 0 && progress === 100 && status === "idle") {
      clearInterval(intervalRef.current)
    }

    if (count > 0 && progress === 100 && status === "idle") {
      dispatch("start")
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(() => {
        dispatch("tick")
      }, 100)
    }
  }, [status, progress, count])

  return (
    <Container {...state}/>
  )
}