import React, { useEffect } from 'react'
import styled from 'styled-components'

const HTMLSelect = styled.select`
  -webkit-appearance: none;
  background: none;
  background-color: ${({ theme, altStyle }) =>
    altStyle ? theme.selectAltBorderColor : theme.selectBorderColor};
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${({ theme, altStyle }) =>
          altStyle ? theme.selectAltBorderColor : theme.selectBorderColor}
        50%
    ),
    linear-gradient(
      135deg,
      ${({ theme, altStyle }) =>
          altStyle ? theme.selectAltBorderColor : theme.selectBorderColor}
        50%,
      transparent 50%
    );
  background-position: calc(100% - 16px) 6px, calc(100% - 10px) 6px;
  background-repeat: no-repeat;
  background-size: 6px 6px, 6px 6px;
  border: none;
  border-radius: 0;
  border-bottom: solid 2px
    ${({ theme, altStyle }) =>
      altStyle ? theme.selectAltBorderColor : theme.selectBorderColor};
  color: ${({ theme, altStyle }) =>
    altStyle ? theme.selectAltTxtColor : theme.selectTxtColor};
  font-size: 20px;
  line-height: 24px;
  outline: none;
  width: 100%;
  padding: 0;
  padding-bottom: 10px;
  margin-top: 0;
  &:focus {
    background-color: ${({ theme, altStyle }) =>
      altStyle
        ? theme.selectAltFocusBorderColor
        : theme.selectFocusBorderColor};
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        ${({ theme, altStyle }) =>
            altStyle
              ? theme.selectAltFocusBorderColor
              : theme.selectFocusBorderColor}
          50%
      ),
      linear-gradient(
        135deg,
        ${({ theme, altStyle }) =>
            altStyle
              ? theme.selectAltFocusBorderColor
              : theme.selectFocusBorderColor}
          50%,
        transparent 50%
      );
    border-bottom: solid 2px
      ${({ theme, altStyle }) =>
        altStyle
          ? theme.selectAltFocusBorderColor
          : theme.selectFocusBorderColor};
    color: ${({ theme, altStyle }) =>
      altStyle ? theme.selectAltFocusTxtColor : theme.selectFocusTxtColor};
  }
`

export default function Select({
  id,
  onChange,
  defaultValue,
  value,
  options = [],
  allowEmpty = false,
  changeTransform = (x) => x,
  valueKey = 'id',
  labelKey = 'name',
}) {
  // changes select value if options are loaded after first component render
  useEffect(() => {
    if (!allowEmpty && value === '' && options.length > 0) {
      onChange(changeTransform(options[0][valueKey]))
    }
    // eslint-disable-next-line
  }, [options, value])

  return (
    <HTMLSelect
      id={id}
      onChange={(e) => onChange(changeTransform(e.target.value))}
      value={value}
      defaultValue={defaultValue}
    >
      {allowEmpty && <option value=''></option>}
      {options.map((x) => (
        <option key={x[valueKey]} value={x[valueKey]}>
          {x[labelKey]}
        </option>
      ))}
    </HTMLSelect>
  )
}
