import React, { useCallback } from "react"

import Form from "routes/LoggedIn/routes/Questions/components/Form"
import useApi from "hooks/useApi"
import questionsApi from "datasources/questions"

function trasnformQuestion(apiQuestion) {
  const {
    incorrectAnswers: [
      incorrectAnswerA,
      incorrectAnswerB,
    ],
    trainingSet: batch,
    ...question
  } = apiQuestion

  return {
    ...question,
    incorrectAnswerA,
    incorrectAnswerB,
    batch,
  }
}

export default function({ match, history }) {
  const { params: { questionId } } = match

  const apiGet = useCallback(ctx => {
    return questionsApi.get(ctx, questionId)
  }, [questionId])

  const {
    data: question,
    isFetching
  } = useApi(apiGet)

  return (
    !isFetching && <Form defaults={trasnformQuestion(question)} history={history}/>
  )
}