import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "routes/LoggedIn/routes/Prizes/routes/List"
import New from "routes/LoggedIn/routes/Prizes/routes/New"
import Detail from "routes/LoggedIn/routes/Prizes/routes/Detail"

export default function() {
  return (
    <Switch>
      <Route exact path="/prizes/new" component={New}/>
      <Route exact path="/prizes/:prizeId" component={Detail}/>
      <Route path="/prizes" component={List}/>
    </Switch>
  )
}