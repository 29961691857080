import React from "react"

import Form from "routes/LoggedIn/routes/Prizes/components/Form"

const defaults = {
  label: "",
  description: "",
  termsAndConditions: "",
  prizeType: "item",
  maxRoll: "0",
  stock: "0",
  imageUrl: null,
}

export default function({ history }) {
  return (
    <Form defaults={defaults} history={history}/>
  )
}