import React, { useCallback, useRef } from "react"
import styled from "styled-components"
import { Times, File } from "@styled-icons/fa-solid"

import ImgixImg from "components/ImgixImg"

const Placeholder = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.fileInputBgColor};
  border-radius: 58px;
  color: white;
  display: flex;
  height: 58px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 58px;
`

const Img = styled(ImgixImg)`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`

const Label = styled.div`
`

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 20px 58px;
  grid-gap: 10px;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: solid 2px ${({ theme }) => theme.fileInputBorderColor};
`

const HTMLInput = styled.input`
  position: absolute;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  height: 58px;
  border-radius: 58px;
  cursor: pointer;
`

const Clear = styled(Times)`
  cursor: pointer;
`

export default function({ placeholderIcon: Icon = File, value, onChange, label, ...props }) {
  const ref = useRef()

  const loadBase64 = useCallback(e => {
    const file = e.target.files[0]

    if ( /\.(jpe?g|png|gif)$/i.test(file.name) ) {
      const reader = new FileReader()

      reader.addEventListener("load", function() {
        onChange(this.result)
      }, false)

      reader.readAsDataURL(file)
    }
  }, [onChange])

  const clear = useCallback(() => {
    onChange(null)
    ref.current.value = ""
  }, [onChange])

  return (
    <Container>
      <Label>
        {label}
      </Label>
      {value ?
        <Clear
          size="20"
          onClick={clear}
        /> :
          <div></div>
      }
      <Placeholder>
        {value ?
          <Img src={value}/> :
            <Icon size="24"/>
        }
        <HTMLInput
          type="file"
          ref={ref}
          onChange={loadBase64}
        />
      </Placeholder>
    </Container>
  )
}
