import React, { useState, createContext, useEffect } from "react"

const Context = createContext()

const KEY = "dailyBonfireTheme"

export function Provider({ children }) {
  const [theme, setTheme] = useState(
    localStorage.getItem(KEY) || "darkTheme"
  )

  useEffect(() => {
    localStorage.setItem(KEY, theme)
  }, [theme])

  const value = {
    theme,
    setTheme
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default Context