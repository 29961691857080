import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Plus, Minus } from '@styled-icons/fa-solid'

import Button from 'components/Button'
import RawInput from 'components/RawInput'
import { slugify } from 'utils/slug'

const Label = styled.div`
  margin-bottom: 10px;
`

const AddButton = styled(Button.Short).attrs({
  icon: Plus,
  altStyle: true,
})`
  margin-left: 10px;
`

const RemoveButton = styled(Button.Short).attrs({
  icon: Minus,
  altStyle: true,
})``

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 30px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: 0 -15px 10px;
`

const Col = styled.div`
  margin: 0 15px;
  ${({ block }) =>
    block &&
    css`
      flex: 1;
    `}
`

export default function ({ value, onChange, label }) {
  const add = useCallback(
    (event) => {
      event.preventDefault()

      onChange([...value, { id: '', label: '', value: '', kind: 'TEXT' }])
    },
    [value, onChange]
  )

  const remove = useCallback(
    (removedIndex) => (event) => {
      event.preventDefault()

      onChange(value.filter((item, index) => removedIndex !== index))
    },
    [value, onChange]
  )

  const changeLabel = useCallback(
    (changedIndex) => (event) => {
      const newLabel = event.target.value

      onChange(
        value.map((item, index) => {
          if (changedIndex !== index) {
            return item
          }

          return {
            ...item,
            id: slugify(newLabel),
            label: newLabel,
          }
        })
      )
    },
    [value, onChange]
  )

  const changeValue = useCallback(
    (changedIndex) => (event) => {
      const newValue = event.target.value

      onChange(
        value.map((item, index) => {
          if (changedIndex !== index) {
            return item
          }

          return {
            ...item,
            value: newValue,
          }
        })
      )
    },
    [value, onChange]
  )

  return (
    <Container>
      <Label>
        {label}
        <AddButton onClick={add} />
      </Label>
      {(value || []).map(({ label, value }, index) => (
        <Row key={index}>
          <Col block>
            <RawInput
              placeholder='Label'
              value={label}
              onChange={changeLabel(index)}
            />
          </Col>
          <Col block>
            <RawInput
              placeholder='Value'
              value={value}
              onChange={changeValue(index)}
            />
          </Col>
          <Col>
            <RemoveButton onClick={remove(index)} />
          </Col>
        </Row>
      ))}
    </Container>
  )
}
