import styled, { css } from "styled-components"

const Cell = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ right }) => right && css`
    direction: rtl;
  `};
  ${({ clickable }) => clickable && css`
    cursor: pointer;
    user-select: none;
  `};
`

Cell.displayName = "Cell"

export default Cell