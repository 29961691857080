import { post } from "datasources/common"

const gcsEnv = process.env.REACT_APP_GCS_ENV || 'production'

const getContentType = base64 =>
  base64
    .split(',')[0]
    .replace(';base64', '')
    .replace('data:', '')

const getData = async base64 => {
  const file = await fetch(base64)
  return file.blob()
}

export default {
  async post(ctx, { path: pathWithoutPrefix, base64, orphan = false }) {
    if (!base64 || base64.indexOf('data:') !== 0) {
      throw new Error('Invalid content to upload')
    }

    const contentType = getContentType(base64)
    const data = await getData(base64)
    const fingerprint =  Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 15)

    const path = `/${orphan ? "orphan" : "public"}/${pathWithoutPrefix}-${fingerprint}`

    const {
      data: { url, headers },
    } = await post(ctx, '/uploads/write-urls', {
      body: { path, contentType },
    })

    await fetch(url, {
      method: 'PUT',
      body: data,
      headers: { ...headers, 'Content-Type': contentType },
    })

    return `${gcsEnv}:${path}`
  },
}
