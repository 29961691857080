import React from "react"
import styled from "styled-components"
import { Box } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Button from "components/Button"
import Input from "components/Input"
import FileInput from "components/FileInput"
import TextArea from "components/TextArea"
import useForm from "hooks/useForm"
import useAppCtx from "hooks/useAppCtx"
import eventsApi from "datasources/events"
import uploadsApi from "datasources/uploads"

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const constraints = {

}

function isBase64(s) {
  return s ? s.indexOf("data:") > -1 : false
}

export default function({ defaults, history }) {
  const ctx = useAppCtx()

  const {
    fields,
    values,
    setAllErrors,
    onSubmit,
  } = useForm({
    constraints,
    defaults,
  })

  async function submitClicked()  {
    try {
      let event = { ...values }

      if (isBase64(event.imageUrl)) {
        const path = `events/${!event.id ? "ownerId/image" : `${event.id}/image`}`
        const base64 = event.imageUrl
        event.imageUrl = await uploadsApi.post(ctx, { path, base64, orphan: !event.id })
      }

      if (event.id) {
        await eventsApi.put(ctx, event)
      } else {
        await eventsApi.post(ctx, event)
      }

      history.push("/events")
    } catch(e) {
      setAllErrors(e)
    }
  }

  return (
    <PageContainer onBack={() => history.push("/events")}>
      <HTMLForm onSubmit={onSubmit(submitClicked)}>
        <Input label="Label" {...fields.label}/>
        <TextArea rows={5} label="Description" {...fields.description}/>
        <FileInput label="Image" placeholderIcon={Box} {...fields.imageUrl} />
        <Input label="Scheduled To" {...fields.scheduledTo}/>
        <Button.Flex>
          Save
        </Button.Flex>
      </HTMLForm>
    </PageContainer>
  )
}
