import React, { useCallback } from "react"
import styled from "styled-components"
import { format } from "date-fns"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import Button from "components/Button"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import eventsApi from "datasources/events"
import { Plus, Image, CalendarAlt, TimesCircle, ArrowCircleRight } from "@styled-icons/fa-solid"
import useAppCtx from "hooks/useAppCtx"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px 40px;
  margin-bottom: 30px;
`

const filterOptions = {
  past: {
    label: "Past",
    queryKey: "scheduledTo",
    queryValue: "item"
  },
  future: {
    label: "Future",
    queryKey: "scheduledTo",
    queryValue: "fabulous"
  },
}

const sortOptions = {
  label: "Label",
  scheduledTo: "Scheduled To",
}

export default function({ history }) {
  const ctx = useAppCtx()
  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "eventsList",
  })

  const { data: events, isFetching, setData } = useApi(eventsApi.list, apiProps)

  const onDelete = useCallback(async id => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      await eventsApi.del(ctx, id)
      setData(
        events.filter(x =>
          x.id !== id
        )
      )
    }
  },[ctx, events, setData])

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
        <Button.Short altStyle icon={Plus} onClick={() => history.push("/events/new")}/>
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {events.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Image
                to={`/events/${x.id}`}
                imageUrl={x.imageUrl}
                fallbackIcon={Image}
              />
              <Table.Cell.Text
                text={x.label}
              />
              <Table.Cell.IconText
                icon={CalendarAlt}
                alt="Scheduled To"
                width={300}
                text={format(new Date(x.scheduledTo), "Pp")}
              />
              <Table.Cell.Icon
                icon={TimesCircle}
                alt="Delete"
                onClick={() => onDelete(x.id)}
              />
              <Table.Cell.IconLink
                icon={ArrowCircleRight}
                alt="Edit"
                to={`/events/${x.id}`}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
