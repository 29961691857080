import React from "react"

import Cell from "components/Table/components/Cell"

function CellIconText({ icon: Icon, alt, text, onClick, visible = true }) {
  return (
    <Cell clickable={!!onClick} onClick={onClick}>
      {visible &&
        <>
          <Icon
            size="20"
            title={alt}
          />
          &nbsp;&nbsp;
          {text}
        </>
      }
    </Cell>
  )
}

CellIconText.displayName = "Cell.CellIconText"

export default CellIconText