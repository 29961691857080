import React, { useCallback } from "react"

import Form from "routes/LoggedIn/routes/Events/components/Form"
import useApi from "hooks/useApi"
import eventsApi from "datasources/events"

export default function({ match, history }) {
  const { params: { eventId } } = match

  const apiGet = useCallback(ctx => {
    return eventsApi.get(ctx, eventId)
  }, [eventId])

  const {
    data: event,
    isFetching
  } = useApi(apiGet)

  return (
    !isFetching && <Form defaults={event} history={history}/>
  )
}