import { get, patch, post, del, buildUrl } from 'datasources/common'

export default {
  async list(ctx, { page, perPage, sort, query, filters = {} }) {
    const params = {
      page,
      perPage,
      sort,
      ...(query !== '' ? { query } : {}),
      ...filters,
    }

    const { data, headers } = await get(ctx, buildUrl('/products', params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
  async post(ctx, product) {
    return await post(ctx, buildUrl(`/products`), { body: product })
  },
  async get(ctx, id) {
    return await get(ctx, buildUrl(`/products/${id}`))
  },
  async patch(ctx, product) {
    return await patch(ctx, buildUrl(`/products/${product.id}`), {
      body: product,
    })
  },
  async del(ctx, product) {
    return await del(ctx, buildUrl(`/products/${product.id}`), {
      body: product,
    })
  },
  async listCategories(ctx, { filters = {} }) {
    return await get(ctx, buildUrl(`/products/categories`, filters))
  },
}
