import React, { useCallback } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import Button from "components/Button"
import useApi from "hooks/useApi"
import useAppCtx from "hooks/useAppCtx"
import useList from "hooks/useList"
import communitiesApi from "datasources/communities"
import tagsApi from "datasources/tags"
import { Plus, TimesCircle, PencilAlt, Tag } from "@styled-icons/fa-solid"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px 40px;
  margin-bottom: 30px;
`

const sortOptions = {
  label: "Label",
  createdAt: "Created At",
}

export default function() {
  const ctx = useAppCtx()
  const history = useHistory()

  const { data: communities } = useApi(communitiesApi.getFilterOptions)

  const filterOptions = {
    ...communities
  }

  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "tagsList",
  })

  const { data: tags, isFetching, setData } = useApi(tagsApi.list, apiProps)

  const onDelete = useCallback(async id => {
    if (window.confirm("Are you sure you want to delete this tag?")) {
      await tagsApi.del(ctx, id)
      setData(
        tags.filter(x =>
          x.id !== id
        )
      )
    }
  },[ctx, tags, setData])

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
        <Button.Short altStyle icon={Plus} onClick={() => history.push("/tags/new")}/>
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {tags.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Image
                to={`/tags/${x.id}/edit`}
                imageUrl={x.imageUrl}
                fallbackIcon={Tag}
                alt={"Tag"}
              />
              <Table.Cell.Text
                text={x.label}
                onClick={() => history.push(`/tags/${x.id}/edit`) }
              />
              <Table.Cell.Text
                text={x.communityName}
                width={140}
              />
              <Table.Cell.Icon
                icon={TimesCircle}
                alt="Delete"
                onClick={() => onDelete(x.id)}
              />
              <Table.Cell.IconLink
                icon={PencilAlt}
                alt="Edit"
                to={`/tags/${x.id}/edit`}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
