import React from "react"

import Cell from "components/Table/components/Cell"

function CellText({ text, style = {}, onClick }) {
  return (
    <Cell style={style} clickable={!!onClick} onClick={onClick}>
      {text}
    </Cell>
  )
}

CellText.displayName = "Cell.Text"

export default CellText