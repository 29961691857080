import React from "react"
import Form from "routes/LoggedIn/routes/Tags/components/Form"

const defaults = {
  label: "",
  description: "",
  imageUrl: null,
  communityId: "",
}

export default function({ history }) {
  return (
    <Form defaults={defaults} history={history}/>
  )
}