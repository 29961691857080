import React, { useMemo } from "react"
import styled from "styled-components"

import RawInput from "components/RawInput"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasErrors }) => hasErrors ? 20 : 30}px;
  width: 100%;
`

const Label = styled.label`
  margin-bottom: 10px;
  width: 100%;
`

const Errors = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.inputErrorTxtColor};
`

export default function({ id, label, errors = [], value, onChange, autoComplete, autoFocus }) {
  const hasErrors = useMemo(() =>
    errors && errors.length > 0,
  [errors])

  return (
    <Container hasErrors={hasErrors}>
      {label &&
        <Label htmlFor={id}>
          {label}
        </Label>
      }
      <RawInput id={id}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
      />
      {hasErrors &&
        <Errors>
          {errors[0]}
        </Errors>
      }
    </Container>
  )
}
