import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "routes/LoggedIn/routes/Tags/routes/List"
import New from "routes/LoggedIn/routes/Tags/routes/New"
// import Detail from "routes/LoggedIn/routes/Contests/routes/Detail"
import Edit from "routes/LoggedIn/routes/Tags/routes/Edit"

export default function() {
  return (
    <Switch>
      <Route exact path="/tags/new" component={New}/>
      {/* <Route exact path="/contests/:contestId" component={Detail}/> */}
      <Route exact path="/tags/:tagId/edit" component={Edit}/>
      <Route exact path="/tags" component={List}/>
    </Switch>
  )
}