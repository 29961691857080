import React from "react"
import styled from "styled-components"
import { Box } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Button from "components/Button"
import Input from "components/Input"
import FileInput from "components/FileInput"
import Select from "components/Select"
import TextArea from "components/TextArea"
import useForm from "hooks/useForm"
import useAppCtx from "hooks/useAppCtx"
import prizesApi from "datasources/prizes"
import uploadsApi from "datasources/uploads"

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const prizeTypes = [
  { id: "item", name: "Regular Prize" },
  { id: "fabulous", name: "Fabulous Prize" },
  { id: "life", name: "Extra Life" },
]

const constraints = {

}

function isBase64(s) {
  return s ? s.indexOf("data:") > -1 : false
}

export default function({ defaults, history }) {
  const ctx = useAppCtx()

  const {
    fields,
    values,
    setAllErrors,
    onSubmit,
  } = useForm({
    constraints,
    defaults,
  })

  async function submitClicked()  {
    try {
      let prize = { ...values }

      if (isBase64(prize.imageUrl)) {
        const path = `prizes/${!prize.id ? "ownerId/image" : `${prize.id}/image`}`
        const base64 = prize.imageUrl
        prize.imageUrl = await uploadsApi.post(ctx, { path, base64, orphan: !prize.id })
      }

      if (prize.id) {
        await prizesApi.put(ctx, prize)
      } else {
        await prizesApi.post(ctx, prize)
      }

      history.push("/prizes")
    } catch(e) {
      setAllErrors(e)
    }
  }

  return (
    <PageContainer onBack={() => history.push("/prizes")}>
      <HTMLForm onSubmit={onSubmit(submitClicked)}>
        <Input label="Label" {...fields.label}/>
        <TextArea rows={5} label="Description" {...fields.description}/>
        <TextArea rows={5} label="Terms & Conditions" {...fields.termsAndConditions}/>
        <Select label="Prize Type" options={prizeTypes} {...fields.prizeType}/>
        <Input label="Likelyhood" {...fields.maxRoll}/>
        <FileInput label="Image" placeholderIcon={Box} {...fields.imageUrl} />
        <Input
          label={values.prizeType === "life" ? "Life #" : "Stock"}
          {...fields.stock}
        />
        <Button.Flex>
          Save
        </Button.Flex>
      </HTMLForm>
    </PageContainer>
  )
}
