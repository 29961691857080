import React from "react"
import Form from "routes/LoggedIn/routes/Communities/components/Form"

const defaults = {
  name: "",
  slug: "",
  trendingEnabled: false,
  trendingSeoDescription: "",
  hashtagSeoDescription: "",
  retailerSeoDescription: "",
  landingBackgroundColor: "",
  googleMapsKeyword: "",
  ageGate: null,
  published: false,
}

export default function({ history }) {
  return (
    <Form defaults={defaults} history={history}/>
  )
}
