import React, { useMemo } from "react"
import ReactExport from "react-export-excel"
import { FileExcel } from "@styled-icons/fa-solid"
import Button from "components/Button"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet


export default function({ data }) {
  const dataSet = useMemo(() => {
    return ([{
      columns: ["", "Correct Answer", "Incorrect Answer", "Incorrect Answer", "Commentary", "ID"],
      data: (data || []).slice().sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map(x => ([
        { value: x.title, style: { fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } } }},
        { value: x.correctAnswer },
        { value: x.incorrectAnswers[0] },
        { value: x.incorrectAnswers[1] },
        { value: x.hostNotes },
        { value: x.id },
        { value: x.createdAt },
      ]))
    }])
  }, [data])

  return (
    data && <ExcelFile element={
      <Button.Short altStyle icon={FileExcel}/>
    }>
      <ExcelSheet dataSet={dataSet} name="Question">
      </ExcelSheet>
    </ExcelFile>
  )
}
