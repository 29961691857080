import React from "react"
import styled from "styled-components"
import { Tag } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Button from "components/Button"
import CommunitySelect from "components/CommunitySelect"
import Input from "components/Input"
import FileInput from "components/FileInput"
import TextArea from "components/TextArea"
import useForm from "hooks/useForm"
import useAppCtx from "hooks/useAppCtx"
import tagsApi from "datasources/tags"
import uploadsApi from "datasources/uploads"

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const constraints = {
  label: { presence: { allowEmpty: false }}
}

function isBase64(s) {
  return s ? s.indexOf("data:") > -1 : false
}

export default function({ defaults, history }) {
  const ctx = useAppCtx()

  const {
    fields,
    values,
    setAllErrors,
    onSubmit,
  } = useForm({
    constraints,
    defaults,
  })

  async function submitClicked()  {
    try {
      let tag = { ...values }
      tag.label = tag.label.trim().toLowerCase()

      if (isBase64(tag.imageUrl)) {
        const path = `tags/${!tag.id ? "ownerId/image" : `${tag.id}/image`}`
        const base64 = tag.imageUrl
        tag.imageUrl = await uploadsApi.post(ctx, { path, base64, orphan: !tag.id })
      }

      if (tag.id) {
        await tagsApi.put(ctx, tag)
      } else {
        await tagsApi.post(ctx, tag)
      }
      history.push("/tags")
    } catch(e) {
      setAllErrors(e)
    }
  }

  return (
    <PageContainer onBack={() => history.push("/tags")}>
      <HTMLForm onSubmit={onSubmit(submitClicked)}>
        <Input label="Label" {...fields.label}/>
        <TextArea rows={5} label="Description" {...fields.description}/>
        <CommunitySelect field={fields.communityId}/>
        <FileInput label="Image" placeholderIcon={Tag} {...fields.imageUrl} />
        <Button.Flex>
          Save
        </Button.Flex>
      </HTMLForm>
    </PageContainer>
  )
}
