import React, { useCallback } from "react"
import styled from "styled-components"
import {
  ArrowCircleRight,
  Folder,
  CheckDouble,
  Plus,
  Tag,
  TimesCircle,
  Copy,
} from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import useAppCtx from "hooks/useAppCtx"
import useClipboard from "hooks/useClipboard"
import questionsApi from "datasources/questions"
import Button from "components/Button"

import Download from "./Download"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px 40px 40px;
  margin-bottom: 30px;
`

const filterOptions = {
  unused: { label: "Unused", queryKey: "isUsed", queryValue: false, removes: ["used"] },
  used: { label: "Used", queryKey: "isUsed", queryValue: true, removes: ["unused"] },
  correctRatio0: { label: "CR 0 - 20%", query: { correctResponseRatioFrom: 0, correctResponseRatioTo: .2}, removes: ["correctRatio20", "correctRatio40", "correctRatio60", "correctRatio80"] },
  correctRatio20: { label: "CR 20 - 40%", query: { correctResponseRatioFrom: .2, correctResponseRatioTo: .4}, removes: ["correctRatio0", "correctRatio40", "correctRatio60", "correctRatio80"] },
  correctRatio40: { label: "CR 40 - 60%", query: { correctResponseRatioFrom: .4, correctResponseRatioTo: .6}, removes: ["correctRatio0", "correctRatio20", "correctRatio60", "correctRatio80"] },
  correctRatio60: { label: "CR 60 - 80%", query: { correctResponseRatioFrom: .6, correctResponseRatioTo: .8}, removes: ["correctRatio0", "correctRatio20", "correctRatio40", "correctRatio80"] },
  correctRatio80: { label: "CR 80 - 100%", query: { correctResponseRatioFrom: .8, correctResponseRatioTo: 1}, removes: ["correctRatio0", "correctRatio20", "correctRatio40", "correctRatio60"] },
}

const sortOptions = {
  createdAt: "Created At",
  title: "Title",
  trainingSet: "Batch",
  correctResponseRatio: "Correct Ratio",
}

export default function({ history }) {
  const ctx = useAppCtx()
  const { copyToClipboard } = useClipboard()

  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps,
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "questionsList",
  })

  const {
    data: questions,
    isFetching,
    setData,
  } = useApi(questionsApi.list, apiProps)

  const onDelete = useCallback(async id => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      await questionsApi.del(ctx, id)
      setData(
        questions.filter(x =>
          x.id !== id
        )
      )
    }
  },[ctx, questions, setData])

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps}/>
        <SearchInput {...searchProps}/>
        <ToggleDropdown {...sortDropdownProps}/>
        <Pager {...pagerProps}/>
        <Button.Short altStyle icon={Plus} onClick={() => history.push("/questions/new")}/>
        <Download data={questions}/>
      </Actions>
      <BadgeList {...filterBadgeListProps}/>
      {!isFetching &&
        <Table>
          {questions.map(x =>
            <Table.Row key={x.id}>
              <Table.Cell.IconText
                icon={Copy}
                alt={x.id}
                width={200}
                text={`${x.id.substr(x.id.length - 12)}`}
                onClick={copyToClipboard(x.id)}
              />
              <Table.Cell.IconText
                icon={Folder}
                alt="Batch"
                width={90}
                text={x.trainingSet}
              />
              <Table.Cell.Text
                text={x.title}
              />
              <Table.Cell.Icon
                icon={CheckDouble}
                alt="Used"
                visible={!!x.usageCount > 0}
              />
              <Table.Cell.Text
                width={110}
                text={`CR ${x.correctResponseRatio*100}%`}
              />
              <Table.Cell.IconText
                icon={Tag}
                alt="Tag"
                width={250}
                text={x.tag}
                visible={!!x.tag}
              />
              <Table.Cell.Icon
                icon={TimesCircle}
                alt="Delete"
                onClick={() => onDelete(x.id)}
              />
              <Table.Cell.IconLink
                icon={ArrowCircleRight}
                alt="Edit"
                to={`/questions/${x.id}`}
              />
            </Table.Row>
          )}
        </Table>
      }
    </PageContainer>
  )
}
