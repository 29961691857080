import React from "react"

import Form from "routes/LoggedIn/routes/Events/components/Form"

const defaults = {
  label: "",
  description: "",
  scheduledTo: (new Date()).toISOString(),
  imageUrl: null,
}

export default function({ history }) {
  return (
    <Form defaults={defaults} history={history}/>
  )
}