import React, { useCallback } from "react"

import Form from "routes/LoggedIn/routes/Tags/components/Form"
import useApi from "hooks/useApi"
import tagsApi from "datasources/tags"

export default function({ match, history }) {
  const { params: { tagId } } = match

  const apiGet = useCallback(ctx => {
    return tagsApi.get(ctx, tagId)
  }, [tagId])

  const {
    data: tag,
    isFetching
  } = useApi(apiGet)

  return (
    !isFetching && <Form defaults={tag} history={history}/>
  )
}