import React, { useMemo, useCallback } from "react"
import styled, { css } from "styled-components"
import { TimesCircle } from "@styled-icons/fa-solid"

const Container = styled.div`
  position: relative;
  & > svg {
    color: ${({ theme, altStyle }) => altStyle ? theme.inputAltBorderColor : theme.inputAltBorderColor};
  }
  &:focus-within > svg {
    color: ${({ theme, altStyle }) => altStyle ? theme.inputAltFocusBorderColor : theme.inputFocusBorderColor};
  }
  & > svg:first-child {
    position: absolute;
    top: 0;
    left: 0;
  }
  & > svg:last-child {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
`

const HTMLInput = styled.input`
  background: none;
  border: none;
  border-bottom: solid 2px ${({ theme, altStyle }) => altStyle ? theme.inputAltBorderColor : theme.inputBorderColor };
  color: ${({ theme, altStyle }) => altStyle ? theme.inputAltTxtColor : theme.inputTxtColor};
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  outline: none;
  padding: 0;
  padding-bottom: 10px;
  width: 100%;
  ${({ hasIcon }) => hasIcon && css`
    padding-left: 30px;
  `};
  ${({ clearable }) => clearable && css`
    padding-right: 30px;
  `};
  &:focus {
    border-bottom: solid 2px ${({ theme, altStyle }) => altStyle ? theme.inputAltFocusBorderColor : theme.inputFocusBorderColor };
    color: ${({ theme, altStyle }) => altStyle ? theme.inputAltFocusTxtColor : theme.inputFocusTxtColor };
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme, altStyle }) => altStyle ? theme.inputAltFocusTxtColor : theme.inputFocusTxtColor };
    -webkit-box-shadow: 0 0 0 500px ${({ theme }) => theme.formBgColor} inset;
    caret-color: white;
  }
`

export default function({ icon: Icon, clearable, value, onChange, altStyle, ...props }) {
  const hasIcon = useMemo(() =>
    !!Icon,
  [Icon])

  const onClear = useCallback(() =>
    onChange(""),
  [onChange])

  return (
    <Container altStyle={altStyle}>
      {hasIcon &&
        <Icon width="20"/>
      }
      <HTMLInput
        hasIcon={hasIcon}
        clearable={clearable}
        value={value || ""}
        onChange={onChange}
        altStyle={altStyle}
        {...props}
      />
      {clearable && value &&
        <TimesCircle width="20" onClick={onClear}/>
      }
    </Container>
  )
}
