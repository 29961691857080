import { get, post, buildUrl } from "datasources/common"

export default {
  async get(ctx, userId) {
    return await get(ctx, buildUrl(`/chats/bans`, { userId }))
  },
  async post(ctx, userId) {
    const kind = "SHADOWBAN"
    const reason = "Trolling"
    return await post(ctx, buildUrl(`/chats/bans/*:*/${userId}`), { body: { kind, reason } })
  },
}