import React from "react"

import { Provider as LoaderProvider } from "contexts/Loader"
import { Provider as AuthProvider } from "contexts/Auth"
import { Provider as ThemeProvider } from "contexts/Theme"

export default function Provider({ children }) {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <AuthProvider>
          {children}
        </AuthProvider>
      </LoaderProvider>
    </ThemeProvider>
  )
}