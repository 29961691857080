import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import ImgixImg from "components/ImgixImg"
import Cell from "components/Table/components/Cell"

const Placeholder = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.tableCellImageBgColor};
  border-radius: 48px;
  color: ${({ theme }) => theme.tableCellImageIconColor};
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  width: 48px;
`

const Img = styled(ImgixImg)`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`

function CellImage({ imageUrl, to, alt, fallbackIcon: FallbackIcon }) {
  const history = useHistory()
  const onClick = useMemo(() => {
    if (to) {
      return () => {
        history.push(to)
      }
    }
  }, [to, history])
  return (
    <Cell clickable={!!onClick}>
      <Placeholder onClick={onClick}>
        {imageUrl ?
          <Img src={imageUrl} alt={alt}/> :
            <FallbackIcon size="26" title={alt}/>
        }
      </Placeholder>
    </Cell>
  )
}

CellImage.displayName = "Cell.Image"
CellImage.defaultProps = { width: 68 }

export default CellImage