import React, { useState } from "react"
import styled, { css } from "styled-components"
import { User, EllipsisV } from "@styled-icons/fa-solid"
import BambuserAPI from "datasources/bambuser"

import ImgixImg from "components/ImgixImg"

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 300px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 5px;
  position: relative;
  ${({ preview }) => css`
    background-image: url(${preview});
    background-size: 100%;
  `}
`

const UsernameAvatar = styled.div`
  display: flex;
  padding: 5px;
  width: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, .3);
  cursor: default;
  & > svg {
    cursor: pointer;
    margin-left: auto;
  }
`

const Avatar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.avatarBgColor};
  border-radius: 36px;
  color: ${({ theme }) => theme.avatarTxtColor};
  display: flex;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  width: 36px;
  flex-grow: 0;
  flex-shrink: 0;
`

const AvatarImg = styled(ImgixImg)`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`

const Username = styled.div`
  color: white;
  font-size: 14px;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Tags = styled.div`
  margin-top: auto;
  padding: 8px;
  padding-bottom: 3px;
  display: flex;
  flex-wrap: wrap;
  user-select: none;
`

const Tag = styled.div`
  color: black;
  width: auto;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.loaderBarBgColor};
`

const Score = styled.div`
  color: black;
  width: auto;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.buttonAltBgColor};
  display: block;
`

const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, .3);
  border-bottom: solid 1px white;
`

const MenuItem = styled.div`
  padding: 10px 5px;
  border-top: solid 1px white;
  font-size: 16px;
  cursor: pointer;
`

const Winner = styled.div`
  color: black;
  width: auto;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  background-color: #ffdf68;
  display: block;
  tex
`

function Stream({ stream, author, children, onDelete = () => {} }) {
  const [isOpen, setIsOpen] = useState(false)
  const streamUrl = `https://dailybonfire.com/@${author.username}/${stream.id}`

  async function del() {
    if (window.confirm("Are you sure you want to delete this stream?")) {
      BambuserAPI.del(stream.id)
      onDelete(stream.id)
    }
  }

  return (
    <Wrapper preview={stream.preview} target="blank" href={streamUrl}>
      <UsernameAvatar onClick={(e) => e.preventDefault()}>
        <Avatar>
          {author.avatarUrl ?
            <AvatarImg src={author.avatarUrl}/> :
              <User size="20"/>
          }
        </Avatar>
        <Username>
          @{author.username}
        </Username>
        <EllipsisV size="20" onClick={(e) => setIsOpen(!isOpen)}/>
      </UsernameAvatar>
      {isOpen && <Menu onClick={(e) => { e.preventDefault(); setIsOpen(false) }}>
        <MenuItem onClick={del}>Delete</MenuItem>
        {children}
      </Menu>}
      <Tags>
        {stream.winner &&
          <Winner>Winner</Winner>
        }
        {!stream.winner && stream.isPreSelected &&
          <Winner>Pre-selected</Winner>
        }
        <Score>{stream.score}</Score>
        {stream.tags.map(tag => (
          <Tag>#{tag}</Tag>
        ))}
      </Tags>
    </Wrapper>
  )
}

Stream.MenuItem = MenuItem

export default Stream
