import React, { useCallback } from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import { format, isPast } from "date-fns"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import Button from "components/Button"
import BadgeList from "components/BadgeList"
import useAppCtx from "hooks/useAppCtx"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import communitiesApi from "datasources/communities"
import contestsApi from "datasources/contests"
import { CheckCircle, Plus, CalendarAlt, Trophy, Gift, Tag, Running, ArrowCircleRight, Video, PencilAlt, Images, TimesCircle } from "@styled-icons/fa-solid"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px 40px;
  margin-bottom: 30px;
`

const defaultFilterOptions = {
  regularPrize: {
    label: "Regular Prizes",
    queryKey: "prizeTypes",
    queryValue: "item"
  },
  fabulousPrize: {
    label: "Fabulous Prizes",
    queryKey: "prizeTypes",
    queryValue: "fabulous"
  },
}

const sortOptions = {
  title: "Title",
  createdAt: "Created At",
  startsAt: "Starts At",
  endsAt: "Ends At",
  maxWinners: "Max Winners",
  entryCount: "# of Entries",
}

function getStatusIcon(contest) {
  const startsAt = new Date(contest.startsAt)
  const endsAt = new Date(contest.endsAt)

  if (isPast(endsAt)) {
    return CheckCircle
  } else if (isPast(startsAt)) {
    return Running
  }
  return CalendarAlt
}

function getStatusAlt(contest) {
  const startsAt = new Date(contest.startsAt)
  const endsAt = new Date(contest.endsAt)

  if (isPast(endsAt)) {
    return `Finished`
  } else if (isPast(startsAt)) {
    return `Running`
  }
  return `Scheduled`
}

export default function() {
  const history = useHistory()
  const ctx = useAppCtx()

  const { data: communities } = useApi(communitiesApi.getFilterOptions)

  const filterOptions = {
    ...defaultFilterOptions,
    ...communities
  }

  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "contestsList",
  })

  const { data: contests, isFetching, setData } = useApi(contestsApi.list, apiProps)

  const onDelete = useCallback(async id => {
    if (window.confirm("Are you sure you want to delete this contest?")) {
      await contestsApi.del(ctx, id)
      setData(
        contests.filter(x =>
          x.id !== id
        )
      )
    }
  },[ctx, contests, setData])

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
        <Button.Short altStyle icon={Plus} onClick={() => history.push("/contests/new")}/>
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {contests.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Image
                to={`/contests/${x.id}`}
                imageUrl={x.featuredImageUrl}
                fallbackIcon={Images}
                alt={"Contest"}
              />
              <Table.Cell.Text
                text={x.title}
                onClick={() => history.push(`/contests/${x.id}`) }
              />
              <Table.Cell.Icon
                icon={getStatusIcon(x)}
                alt={getStatusAlt(x)}
              />
              <Table.Cell.Text
                text={format(new Date(x.startsAt), "MM/dd/yyyy")}
                width={140}
              />
              <Table.Cell.Text
                text={format(new Date(x.endsAt), "MM/dd/yyyy")}
                width={140}
              />
              <Table.Cell.Text
                text={x.communityName}
                width={140}
              />
              <Table.Cell.IconText
                icon={Video}
                alt="# of Entries"
                width={80}
                text={x.entryCount}
              />
              <Table.Cell.IconText
                icon={Trophy}
                alt="Max Winners"
                width={80}
                text={x.maxWinners}
              />
              <Table.Cell.IconText
                icon={Tag}
                alt="Tags"
                width={350}
                text={x.tags.map(x => x.label).join(",")}
              />
              <Table.Cell.Image
                to={`/prizes/${x.prize.id}`}
                imageUrl={x.prize.imageUrl}
                fallbackIcon={Gift}
                alt={"Prize"}
              />
              <Table.Cell.Text
                text={x.prize.label}
                width={300}
              />
              <Table.Cell.Icon
                icon={TimesCircle}
                alt="Delete"
                onClick={() => onDelete(x.id)}
              />
              <Table.Cell.IconLink
                icon={PencilAlt}
                alt="Edit"
                to={`/contests/${x.id}/edit`}
              />
              <Table.Cell.IconLink
                icon={ArrowCircleRight}
                alt="View"
                to={`/contests/${x.id}`}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
