import React from "react"
import styled from "styled-components"

import PageContainer from "components/PageContainer"
import Button from "components/Button"
import Input from "components/Input"
import Select from "components/Select"
import TextArea from "components/TextArea"
import useForm from "hooks/useForm"
import useAppCtx from "hooks/useAppCtx"
import questionsApi from "datasources/questions"

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const tags = [
  { id: "Anxiety", name: "Anxiety" },
  { id: "Article Question", name: "Article Question" },
  { id: "Brand", name: "Brand" },
  { id: "Cannabis 101", name: "Cannabis 101" },
  { id: "Cannabis History", name: "Cannabis History" },
  { id: "Christmas", name: "Christmas" },
  { id: "Chronic Pain", name: "Chronic Pain" },
  { id: "Culture & Lifestyle", name: "Culture & Lifestyle" },
  { id: "Depression", name: "Depression" },
  { id: "DIY & Recipes", name: "DIY & Recipes" },
  { id: "Games", name: "Games" },
  { id: "Halloween", name: "Halloween" },
  { id: "Health", name: "Health" },
  { id: "Hemp-related", name: "Hemp-related" },
  { id: "Industry Spotlight", name: "Industry Spotlight" },
  { id: "Music", name: "Music" },
  { id: "News & Events", name: "News & Events" },
  { id: "Peth Health", name: "Peth Health" },
  { id: "Politics", name: "Politics" },
  { id: "Psychedelics", name: "Psychedelics" },
  { id: "Science & Research", name: "Science & Research" },
  { id: "Sexual Health", name: "Sexual Health" },
  { id: "Sports", name: "Sports" },
  { id: "Strains & Products", name: "Strains & Products" },
  { id: "Thanksgiving", name: "Thanksgiving" },
  { id: "Women's Health", name: "Women's Health" },
]

const constraints = {
  batch: { presence: { allowEmpty: false } },
  title: { presence: { allowEmpty: false } },
  correctAnswer: { presence: { allowEmpty: false } },
  incorrectAnswerA: { presence: { allowEmpty: false } },
  incorrectAnswerB: { presence: { allowEmpty: false } },
  hostNotes: { presence: { allowEmpty: false } },
}

export default function({ defaults, history }) {
  const ctx = useAppCtx()

  const {
    fields,
    values,
    setAllErrors,
    onSubmit,
  } = useForm({
    constraints,
    defaults,
  })

  async function submitClicked()  {
    try {
      const {
        id,
        batch: trainingSet,
        title,
        correctAnswer,
        incorrectAnswerA,
        incorrectAnswerB,
        hostNotes,
        tag,
      } = values

      let payload = {
        trainingSet,
        title,
        correctAnswer,
        incorrectAnswers: [
          incorrectAnswerA,
          incorrectAnswerB,
        ],
        hostNotes,
        tag,
      }

      if (id) {
        await questionsApi.put(ctx, { id, ...payload})
      } else {
        await questionsApi.post(ctx, payload)
      }

      history.push("/questions")
    } catch(e) {
      setAllErrors(e)
    }
  }

  return (
    <PageContainer onBack={() => history.push("/questions")}>
      <HTMLForm onSubmit={onSubmit(submitClicked)}>
        <Input label="Batch #" {...fields.batch}/>
        <TextArea label="Title" rows={5} {...fields.title}/>
        <Input label="Correct Answer" {...fields.correctAnswer}/>
        <Input label="Incorrect Answer A" {...fields.incorrectAnswerA}/>
        <Input label="Incorrect Answer B" {...fields.incorrectAnswerB}/>
        <Select label="Tag" options={tags} {...fields.tag}/>
        <TextArea label="Host Notes" rows={5} {...fields.hostNotes}/>
        <Button.Flex>
          Save
        </Button.Flex>
      </HTMLForm>
    </PageContainer>
  )
}
