import { get, patch, buildUrl } from "datasources/common"

export default {
  async list(ctx, { page, perPage, sort, query, filters = {} }) {
    const params = {
      page,
      perPage,
      sort,
      ...(query !== "" ? { query } : {}),
      ...filters
    }

    const { data, headers } = await get(
      ctx,
      buildUrl("/trivia-engine/transactions", params)
    )
    const total = parseInt(headers["x-total-count"])
    return { data, total }
  },

  async patch(ctx, id, userId) {
    return await patch(
      ctx,
      buildUrl(`/trivia-engine/wallets/${userId}/transactions/${id}`),
      {
        body: { status: "completed" }
      }
    )
  }
}
