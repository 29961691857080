import React, { useMemo, useCallback } from "react"
import styled from "styled-components"
import { Plus } from "@styled-icons/fa-solid"
import RawFileInput from "components/RawFileInput"

const Label = styled.div`
  margin-bottom: 10px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: solid 2px ${({ theme }) => theme.fileInputBorderColor};
`

const Items = styled.div`
  display: flex;
  width: 100%;
`

export default function({ value, onChange, label, }) {
  const newValue = useMemo(() => {
    return [...value.filter(x => !!x), null]
  }, [value])

  const change = useCallback((idx) => (val) => {
    let tmp = [...value]
    tmp[idx] = val
    onChange(tmp.filter(x => !!x))
  }, [value, onChange])

  return (
    <Container>
      <Label>
        {label}
      </Label>
      <Items>
        {newValue.map((val, idx) => {
          return (
            <RawFileInput
              placeholderIcon={Plus}
              key={idx}
              value={val}
              onChange={change(idx)}
            />
          )
        })}
      </Items>
    </Container>
  )
}
