import { get, post, put, patch, del, buildUrl } from 'datasources/common'

export default {
  async list(ctx, { page, perPage, sort, query, filters = {} }) {
    const params = {
      page,
      perPage,
      sort,
      ...(query !== '' ? { query } : {}),
      ...filters,
    }

    const { data, headers } = await get(ctx, buildUrl('/users', params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
  async get(ctx, id) {
    return await get(ctx, buildUrl(`/users/${id}`))
  },
  async put(ctx, user) {
    return await put(ctx, buildUrl(`/users/${user.id}/admin`), { body: user })
  },
  async postPartner(ctx, user) {
    return await post(ctx, buildUrl(`/users/partners`), { body: user })
  },
  async patchData(ctx, user) {
    return await patch(ctx, buildUrl(`/users/${user.id}/admin`), { body: user })
  },
  async patch(ctx, { resendCreatorProgramInvitation, ...user }) {
    return await patch(
      ctx,
      buildUrl(
        `/users/${user.id}/admin?resendCreatorProgramInvitation=${resendCreatorProgramInvitation}`
      ),
      { body: user }
    )
  },
  async del(ctx, user) {
    return await del(ctx, buildUrl(`/users/${user.id}`))
  },
  async ban(ctx, { id, banReason }) {
    return await post(ctx, buildUrl(`/users/${id}/ban`), {
      body: { banReason },
    })
  },
  async putRole(ctx, user) {
    const basePath = `/authorization/users/${user.id}/roles`
    if (user.originalRole !== 'basic' && user.originalRole !== null) {
      await del(ctx, buildUrl(`${basePath}/${user.originalRole}`))
    }

    if (user.role !== 'basic' && user.role !== null) {
      await put(ctx, buildUrl(`${basePath}/${user.role}`))
    }

    return
  },
  async postLife(ctx, id) {
    return await post(ctx, buildUrl(`/trivia-engine/lives/${id}`))
  },
  async listPartners(ctx, { filters }) {
    const { data, headers } = await get(
      ctx,
      buildUrl('/users', { isPartner: true, perPage: 100, ...filters })
    )
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
}
