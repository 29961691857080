import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

import Users from "routes/LoggedIn/routes/Users"
import Questions from "routes/LoggedIn/routes/Questions"
import Prizes from "routes/LoggedIn/routes/Prizes"
import Transactions from "routes/LoggedIn/routes/Transactions/routes/List"
import Games from "routes/LoggedIn/routes/Games/routes/List"
import GameResults from "routes/LoggedIn/routes/GameResults/routes/List"
import Checkins from "routes/LoggedIn/routes/Checkins/routes/List"
import Contests from "routes/LoggedIn/routes/Contests"
import Events from "routes/LoggedIn/routes/Events"
import Streams from "routes/LoggedIn/routes/Streams"
import Tags from "routes/LoggedIn/routes/Tags"
import Products from "routes/LoggedIn/routes/Products"
import Communities from "routes/LoggedIn/routes/Communities"
import NewPartner from "routes/LoggedIn/routes/Partners/routes/New"

export default function() {
  return (
    <Switch>
      <Route path="/users" component={Users}/>
      <Route path="/questions" component={Questions}/>
      <Route path="/prizes" component={Prizes}/>
      <Route path="/transactions" component={Transactions}/>
      <Route path="/games" component={Games}/>
      <Route path="/game-results" component={GameResults}/>
      <Route path="/streams" component={Streams}/>
      <Route path="/contests" component={Contests}/>
      <Route path="/checkins" component={Checkins}/>
      <Route path="/events" component={Events}/>
      <Route path="/tags" component={Tags}/>
      <Route path="/products" component={Products}/>
      <Route path="/partners/new" component={NewPartner}/>
      <Route path="/communities" component={Communities}/>
      <Redirect to="/users"/>
    </Switch>
  )
}
