import React from "react"
import styled from "styled-components"
import { ArrowLeft } from "@styled-icons/fa-solid"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 70px 30px;
`

const Back = styled(ArrowLeft)`
  margin-bottom: 30px;
  cursor: pointer;
`

export default function({ onBack, children }) {
  return (
    <Container>
      {onBack && <Back size="20" onClick={onBack} />}
      {children}
    </Container>
  )
}
