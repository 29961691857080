import React, { useCallback } from "react"
import styled from "styled-components"
import PageContainer from "components/PageContainer"
import Stream from "components/Stream"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import communitiesApi from "datasources/communities"
import streamsApi from "datasources/streams"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px;
  margin-bottom: 30px;
`

const Streams = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
`

const sortOptions = {
  "createdAt": "CreatedAt",
  "score": "Score",
}

function getAuthor(stream) {
  const { username, avatarUrl } = stream
  return { username, avatarUrl }
}

export default function() {
  const { data: communities } = useApi(communitiesApi.getFilterOptions)

  const filterOptions = {
    ...communities
  }

  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "streams",
  })

  const { data: streams, setData: setStreams, isFetching } = useApi(streamsApi.list, apiProps)
  const deleteStream = useCallback(id => {
    setStreams(streams.filter(x => x.id !== id))
  }, [streams, setStreams])

  return (
    <PageContainer>
      <Actions>
      <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Streams>
          {streams.map(stream => (
            <Stream stream={stream} author={getAuthor(stream)} onDelete={deleteStream}/>
          ))}
        </Streams>
      )}
    </PageContainer>
  )
}
