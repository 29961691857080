import React from "react"
import styled from "styled-components"

import PageContainer from "components/PageContainer"
import Input from "components/Input"
import FileInput from "components/FileInput"
import Select from "components/Select"
import Button from "components/Button"
import useForm from "hooks/useForm"
import useAppCtx from "hooks/useAppCtx"
import communitiesApi from "datasources/communities"
import uploadsApi from "datasources/uploads"

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const constraints = {}

const booleanOpts = [
  { id: true, name: "Yes" },
  { id: false, name: "No" },
]

const ageGateOpts = [
  { id: null, name: "Disabled" },
  { id: 'adult', name: "Adult" },
  { id: 'teen', name: "Teen" },
]

const convertToBoolean = val => (val === 'true' ? true : val === 'false' ? false : val)

function isBase64(s) {
  return s ? s.indexOf("data:") > -1 : false
}

export default function({ defaults, history }) {
  const ctx = useAppCtx()

  const {
    fields,
    values,
    setAllErrors,
    onSubmit,
  } = useForm({
    constraints,
    defaults,
  })

  async function submitClicked()  {
    try {
      let community = { ...values }
      community.published = convertToBoolean(community.published)
      community.config = {
        trendingEnabled: convertToBoolean(community.trendingEnabled),
        googleMapsKeyword: community.googleMapsKeyword,
        ageGate: community.ageGate,
        landingBackgroundColor: community.landingBackgroundColor,
        trendingSeoDescription: community.trendingSeoDescription,
        hashtagSeoDescription: community.hashtagSeoDescription,
        retailerSeoDescription: community.retailerSeoDescription,
      }
      delete community.trendingEnabled
      delete community.landingBackgroundColor
      delete community.trendingSeoDescription
      delete community.hashtagSeoDescription
      delete community.retailerSeoDescription
      delete community.googleMapsKeyword
      delete community.ageGate

      if (!community.landingImageUrl) {
        community.config.landingImageUrl = null
      }

      if (isBase64(community.landingImageUrl) && community.id) {
        const path = `communities/${community.id}/landingImage/image`
        const base64 = community.landingImageUrl
        community.landingImageUrl = await uploadsApi.post(ctx, { path, base64 })
      }

      if (community.landingImageUrl) {
        community.config.landingImageUrl = community.landingImageUrl
      }
      delete community.landingImageUrl

      if (!community.appIconUrl) {
        community.config.appIconUrl = null
      }

      if (isBase64(community.appIconUrl) && community.id) {
        const path = `communities/${community.id}/appIcon/image`
        const base64 = community.appIconUrl
        community.appIconUrl = await uploadsApi.post(ctx, { path, base64 })
      }

      if (community.appIconUrl) {
        community.config.appIconUrl = community.appIconUrl
      }
      delete community.appIconUrl

      if (community.id) {
        await communitiesApi.put(ctx, community)
      } else {
        await communitiesApi.post(ctx, community)
      }

      history.push("/communities")
    } catch(e) {
      setAllErrors(e)
    }
  }

  return (
    <PageContainer onBack={() => history.push("/communities")}>
      <HTMLForm onSubmit={onSubmit(submitClicked)}>
        <Input label="Name" {...fields.name}/>
        <Input label="Slug" {...fields.slug}/>
        <Input label="Landing Background Color" {...fields.landingBackgroundColor}/>
        <Input label="Trending SEO Description" {...fields.trendingSeoDescription}/>
        <Input label="Hashtag Default SEO Description" {...fields.hashtagSeoDescription}/>
        <Input label="Retailer Default SEO Description" {...fields.retailerSeoDescription}/>
        <Input label="Google Maps Keyword" {...fields.googleMapsKeyword}/>
        <Select label="Age gate" options={ageGateOpts} {...fields.ageGate} />
        <Select label="Enable Trending" options={booleanOpts} {...fields.trendingEnabled} />
        <Select label="Published" options={booleanOpts} {...fields.published} />
        {values.id && <FileInput label="Landing Image" {...fields.landingImageUrl} />}
        {values.id && <FileInput label="App Icon" {...fields.appIconUrl} />}
        <Button.Flex>
          Save
        </Button.Flex>
      </HTMLForm>
    </PageContainer>
  )
}
