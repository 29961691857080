import { get, buildUrl } from "datasources/common"

export default {
  async list(ctx, { page, perPage, sort, query, filters = {} }) {
    const params = {
      page,
      perPage,
      sort,
      ...(query !== "" ? { query } : {}),
      ...filters,
    }

    const { data, headers } = await get(ctx, buildUrl("/golive-streams", params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  }
}