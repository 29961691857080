const broadcastUrl = id => `https://api.bambuser.com/broadcasts/${id}`;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/vnd.bambuser.v1+json",
  Authorization: "Bearer BFE7g5BzDJUgGim3Hasxh7"
};

export async function del(id) {
  await fetch(broadcastUrl(id), { headers, method: "DELETE" });
}

export default { del }