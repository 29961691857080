import React from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { Plus, DollarSign, PencilAlt, MapMarkerAlt } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import Button from "components/Button"
import BadgeList from "components/BadgeList"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import productsApi from "datasources/products"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px 40px;
  margin-bottom: 30px;
`

const filterOptions = {}

const sortOptions = {
  createdAt: "Created At",
  name: "Name",
  priceCents: "Price",
  "category.name": "Category",
  "partner.username": "Partner",
}

export default function() {
  const history = useHistory()

  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "productsList",
  })

  const {
    data: products,
    isFetching,
  } = useApi(productsApi.list, apiProps)

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
        <Button.Short altStyle icon={Plus} onClick={() => history.push("/products/new")}/>
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {products.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Text
                text={x.name}
                onClick={() => history.push(`/products/${x.id}`) }
              />
              <Table.Cell.Text
                text={x.partner.username}
              />
              <Table.Cell.Text
                text={x.category.name}
              />
              <Table.Cell.IconText
                text={(x.priceCents / 100).toFixed(2)}
                icon={DollarSign}
              />
              <Table.Cell.IconText
                text={x.states.map(x => x.iso).join(", ")}
                icon={MapMarkerAlt}
              />
              <Table.Cell.IconLink
                icon={PencilAlt}
                alt="Edit"
                to={`/products/${x.id}/edit`}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
