import React from 'react'
import styled, { css } from 'styled-components'

const HTMLButton = styled.button`
  background-color: ${({ theme, altStyle }) =>
    altStyle ? theme.buttonAltBgColor : theme.buttonBgColor};
  border: none;
  color: ${({ theme, altStyle }) =>
    altStyle ? theme.buttonAltTxtColor : theme.buttonTxtColor};
  cursor: pointer;
  font-size: 20px;
  line-height: 40px;
  padding: 0 30px;
  margin: 0
    ${({ iconAlign }) =>
      iconAlign === 'left' &&
      css`
        & > svg {
          margin-right: 10px;
        }
      `};
  ${({ iconAlign }) =>
    iconAlign === 'right' &&
    css`
      & > svg {
        margin-left: 10px;
      }
    `};
`

function Button({ icon: Icon, iconAlign, children, ...props }) {
  return (
    <HTMLButton iconAlign={children ? iconAlign : null} {...props}>
      {Icon && iconAlign !== 'right' && <Icon size='20' />}
      {children}
      {Icon && iconAlign === 'right' && <Icon size='20' />}
    </HTMLButton>
  )
}

Button.Flex = styled(Button)`
  padding: 0;
  width: 100%;
`

Button.Short = styled(Button)`
  padding: 0 10px;
`

export default Button
