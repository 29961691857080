import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    max-width: 100%;
  }

  body {
    background-color: ${({ theme }) => theme.pageBgColor};
    color: ${({ theme }) => theme.pageTxtColor};
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  #root {
    display: flex;
    min-height: 100vh;
  }

  a {
    color: ${({ theme }) => theme.pageLinkColor};
    text-decoration: none;
  }

  form {
    width: 100%;
  }
`