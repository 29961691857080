import React from "react"
import styled from "styled-components"
import { User } from "@styled-icons/fa-solid"

import ImgixImg from "components/ImgixImg"

const Placeholder = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.avatarBgColor};
  border-radius: 96px;
  color: ${({ theme }) => theme.avatarTxtColor};
  display: flex;
  height: 96px;
  justify-content: center;
  overflow: hidden;
  width: 96px;
`

const AvatarImg = styled(ImgixImg)`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`

export default function ({ avatarUrl }) {
  return (
      <Placeholder>
        {avatarUrl ?
          <AvatarImg src={avatarUrl}/> :
            <User size="56"/>
        }
      </Placeholder>
  )
}
