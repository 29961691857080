import React from "react"
import styled from "styled-components"

import Row from "components/Table/components/Row"
import Cell from "components/Table/components/Cell"
import CellImage from "components/Table/components/CellImage"
import CellIcon from "components/Table/components/CellIcon"
import CellText from "components/Table/components/CellText"
import CellLink from "components/Table/components/CellLink"
import CellIconText from "components/Table/components/CellIconText"
import CellIconLink from "components/Table/components/CellIconLink"

const HTMLTable = styled.table`
  background-color: ${({ theme }) => theme.tableRowBgColor};
  border-collapse: collapse;
  color: ${({ theme }) => theme.tableRowTxtColor};
  table-layout: fixed;
  font-size: 20px;
  width: 100%;
  & tr {
    border-bottom: solid 1px ${({ theme }) => theme.tableRowBorderColor};
  }
  & tr:hover {
    background-color: ${({ theme }) => theme.tableRowHoverBgColor};
    color: ${({ theme }) => theme.tableRowHoverTxtColor};
  }
  & tr td {
    line-height: 48px;
    padding: 10px;
  }
  & tr td:first-child {
    padding-left: 20px;
  }
  & tr td:last-child {
    padding-right: 20px;
  }
`

function Table({ children }) {
  const cellCount = children[0] && children[0].props.children.length

  return (
    <HTMLTable>
      <colgroup>
        {children[0] && children[0].props.children.map(({ props: { width = "auto" } }, i) => {
          const extraWidth = (cellCount - 1 === i || i === 0) && width !== "auto" ? 10 : 0
          return <col key={i} style={{ width: width + extraWidth }}/>
        })}
      </colgroup>
      <tbody>
        {children.map(({ key, props: { children } }) =>
          <Row key={key}>
            {children}
          </Row>
        )}
      </tbody>
    </HTMLTable>
  )
}

Table.Row = Row
Table.Cell = Cell
Table.Cell.Image = CellImage
Table.Cell.Icon = CellIcon
Table.Cell.Text = CellText
Table.Cell.Link = CellLink
Table.Cell.IconText = CellIconText
Table.Cell.IconLink = CellIconLink

export default Table