import React, { useCallback } from "react"

import Form from "routes/LoggedIn/routes/Prizes/components/Form"
import useApi from "hooks/useApi"
import prizesApi from "datasources/prizes"

export default function({ match, history }) {
  const { params: { prizeId } } = match

  const apiGet = useCallback(ctx => {
    return prizesApi.get(ctx, prizeId)
  }, [prizeId])

  const {
    data: prize,
    isFetching
  } = useApi(apiGet)

  return (
    !isFetching && <Form defaults={prize} history={history}/>
  )
}