import React from 'react'
import Form from 'routes/LoggedIn/routes/Products/components/Form'

const defaults = {
  name: '',
  description: '',
  price: 0,
  communityId: undefined,
  categoryId: undefined,
  partnerId: undefined,
  images: [],
  state: '',
  url: '',
  metadata: [],
}

export default function ({ history }) {
  return <Form defaults={defaults} history={history} />
}
