import React from "react"

import Cell from "components/Table/components/Cell"

function CellIcon({ icon: Icon, onClick, visible = true, alt }) {
  return (
    <Cell clickable={!!onClick}>
      {visible &&
        <Icon
          width="20"
          title={alt}
          onClick={onClick}
          style={{verticalAlign: "middle"}}
        />
      }
    </Cell>
  )
}

CellIcon.displayName = "Cell.Icon"
CellIcon.defaultProps = { width: 40 }

export default CellIcon