import { get, post, put, del, buildUrl } from "datasources/common"

export default {
  async list(ctx, { page, perPage, sort, query, filters = {} }) {
    const params = {
      page,
      perPage,
      sort,
      ...(query !== "" ? { label: query } : {}),
      ...filters,
    }

    const { data, headers } = await get(ctx, buildUrl("/golive-tags", params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
  async getMultiple({ ctx, labels, communityId }) {
    const params = { labels, communityId }
    const { data, headers } = await get(ctx, buildUrl("/golive-tags", params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
  async post(ctx, tag) {
    return await post(ctx, buildUrl(`/golive-tags`), { body: tag })
  },
  async get(ctx, id) {
    return await get(ctx, buildUrl(`/golive-tags/${id}`))
  },
  async put(ctx, tag) {
    return await put(ctx, buildUrl(`/golive-tags/${tag.id}`), { body: tag })
  },
  async del(ctx, id) {
    return await del(ctx, buildUrl(`/golive-tags/${id}`))
  },
}