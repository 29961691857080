import { get, put, post, del, buildUrl } from "datasources/common"

export default {
  async list(ctx, { page = 0, perPage = 20, sort = "", query = "", filters = {} }) {
    const params = {
      page,
      perPage,
      ...(sort !== "" ? { sort } : {}),
      ...(query !== "" ? { query } : {}),
      ...filters,
    }

    const { data, headers } = await get(ctx, buildUrl("/check-in-prizes/prizes", params))
    const total = parseInt(headers['x-total-count'])
    return { data, total }
  },
  async get(ctx, id) {
    return await get(ctx, buildUrl(`/check-in-prizes/prizes/${id}`))
  },
  async post(ctx, prize) {
    prize.stock = parseInt(prize.stock)
    prize.maxRoll = parseInt(prize.maxRoll)
    return await post(ctx, buildUrl(`/check-in-prizes/prizes`), { body: prize })
  },
  async put(ctx, prize) {
    prize.stock = parseInt(prize.stock)
    prize.maxRoll = parseInt(prize.maxRoll)
    return await put(ctx, buildUrl(`/check-in-prizes/prizes/${prize.id}`), { body: prize })
  },
  async del(ctx, id) {
    return await del(ctx, buildUrl(`/check-in-prizes/prizes/${id}`))
  },
}