import React, { useCallback } from "react"
import styled from "styled-components"
import { format } from "date-fns"
import {
  ArrowCircleRight,
  Ban,
  CalendarAlt,
  Envelope,
  Medal,
  Phone,
  UserTie,
  Wallet,
  Heart,
  User,
  Trophy,
  StarHalfAlt,
  Star,
} from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import Button from "components/Button"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import communitiesApi from "datasources/communities"
import usersApi from "datasources/users"
import useAppCtx from "hooks/useAppCtx"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px 130px;
  margin-bottom: 30px;
`

const defaultFilterOptions = {
  customers: {
    label: "Customers",
    queryKey: "role",
    queryValue: "basic"
  },
  adminsitrators: {
    label: "Admins",
    queryKey: "role",
    queryValue: ["admin", "triviaAdmin"]
  },
  creators: {
    label: "Creators",
    queryKey: "creatorStatus",
    queryValue: "CREATOR"
  },
  invitedCreators: {
    label: "Invited Creators",
    queryKey: "creatorStatus",
    queryValue: "INVITED"
  },
  triviaAdminsitrators: {
    label: "Trivia Admins",
    queryKey: "role",
    queryValue: "triviaAdmin"
  },
  verifiedEmail: {
    label: "Verified E-mail",
    queryKey: "isEmailVerified",
    queryValue: true
  },
  verifiedPhone: {
    label: "Verified Phone",
    queryKey: "isPhoneVerified",
    queryValue: true
  },
  // silenced: { label: "Silenced", queryKey: "silenced", queryValue: true },
  banned: { label: "Banned", queryKey: "isBanned", queryValue: true },
  notBanned: { label: "Not Banned", queryKey: "isBanned", queryValue: false },
  partner: { label: "Partner", queryKey: "isPartner", queryValue: true },
}

const sortOptions = {
  createdAt: "Created At",
  username: "Username",
  totalEarnedCents: "Rank",
  balanceCents: "Balance",
  lifeCount: "Lives",
  bannedAt: "Banned At",
  earnedTransactionsCount: "Wins",
}

export default function({ history }) {
  const ctx = useAppCtx()

  const { data: communities } = useApi(communitiesApi.getFilterOptions)

  const filterOptions = {
    ...defaultFilterOptions,
    ...communities,
  }

  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "usersList",
  })

  const { data: users, setData, isFetching } = useApi(usersApi.list, apiProps)

  const giveLife = useCallback((id) => () => {
    const currUsers = users
    usersApi.postLife(ctx, id)
    const userIdx = currUsers.findIndex(x => x.id === id)
    currUsers[userIdx] = {
      ...currUsers[userIdx],
      lifeCount: (currUsers[userIdx].lifeCount || 0) + 1
    }
    setData(currUsers)
  }, [ctx, users, setData])

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
        <Button.Short altStyle onClick={() => history.push("/partners/new")}>Add partner</Button.Short>
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {users.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Image
                to={`/users/${x.id}`}
                imageUrl={!x.bannedAt && x.avatarUrl}
                fallbackIcon={!x.bannedAt ? User : Ban}
                alt={!x.bannedAt ? x.username : `Banned: ${x.banReason}`}
              />
              <Table.Cell.Icon
                icon={x.creatorStatus ===  "INVITED" ? StarHalfAlt : Star}
                alt={x.creatorStatus ===  "INVITED" ? "Invited to Creator Program" : "Creator"}
                visible={!!(x.creatorStatus === "INVITED" || x.creatorStatus === "CREATOR")}
              />
              <Table.Cell.Link
                to={`/users/${x.id}`}
                text={x.username}
              />
              <Table.Cell.IconText
                icon={Heart}
                alt="Lives"
                onClick={giveLife(x.id)}
                width={100}
                text={x.lifeCount || 0}
              />
              <Table.Cell.IconText
                icon={Trophy}
                alt="Wins"
                width={100}
                text={x.earnedTransactionsCount || 0}
              />
              <Table.Cell.IconText
                icon={Medal}
                alt="Rank"
                width={140}
                text={`$${((x.totalEarnings || 0) / 100).toFixed(2)}`}
              />
              <Table.Cell.IconText
                icon={Wallet}
                alt="Wallet Balance"
                width={140}
                text={`$${((x.walletBalance || 0) / 100).toFixed(2)}`}
              />
              <Table.Cell.IconText
                icon={CalendarAlt}
                alt="Created At"
                width={180}
                text={format(new Date(x.createdAt), "MM/dd/yyyy'")}
              />
              <Table.Cell.Text
                text={x.isPartner ? x.partnerCommunityName : x.communityName}
                width={140}
              />
              <Table.Cell.Icon
                icon={UserTie}
                alt="Admin"
                visible={!!(x.role === "admin" || x.role === "triviaAdmin")}
              />

              <Table.Cell.Icon
                icon={Phone}
                alt="Phone Verified"
                visible={!!x.isPhoneVerified}
              />
              <Table.Cell.Icon
                icon={Envelope}
                alt="Email Verified"
                visible={!!x.isEmailVerified}
              />
              <Table.Cell.IconLink
                icon={ArrowCircleRight}
                alt="Edit"
                to={`/users/${x.id}`}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
