import React, { useState, useCallback, useEffect } from "react"
import styled, { css } from "styled-components"

import Button from "components/Button"

const Container = styled.div`
  position: relative;
`

const Dropdown = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  min-width: 100%;
  z-index: 10;
`

const Item = styled.div`
  background-color: ${({ theme }) => theme.toggleDropdownItemBgColor};
  color: ${({ theme }) => theme.toggleDropdownItemTxtColor};
  cursor: pointer;
  line-height: 30px;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ selected }) => selected && css`
    background-color: ${({ theme }) => theme.toggleDropdownItemSelBgColor};
    color: ${({ theme }) => theme.toggleDropdownItemSelTxtColor};
  `};
  &:hover {
    background-color: ${({ theme }) => theme.toggleDropdownItemHoverBgColor};
    color: ${({ theme }) => theme.toggleDropdownItemHoverTxtColor};
  }
`

export default function({
  icon,
  label,
  options,
  selectedOptions,
  buttonAltStyle = true,
  onSelect = () => {},
  onUnselect = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = useCallback(() =>
    setIsOpen(!isOpen)
  , [isOpen])

  useEffect(() => {
    if (isOpen) {
      const close = () => setIsOpen(false)
      window.addEventListener("click", close)
      return () => window.removeEventListener("click", close)
    }
  }, [isOpen])

  return (
    <Container>
      <Button.Flex altStyle={buttonAltStyle} icon={icon} iconAlign="left" onClick={toggleIsOpen}>
        {label || options[selectedOptions[0]]}
      </Button.Flex>
      {isOpen &&
        <Dropdown>
          {Object.entries(options).map(([k, v], i) => {
            const selected = selectedOptions.includes(k)
            const onClick = () => selected ? onUnselect(k) : onSelect(k)
            return (
              <Item
                key={i}
                selected={selected}
                onClick={onClick}
              >
                { typeof v === 'string' ? v : v.label }
              </Item>
            )
          })}
        </Dropdown>
      }
    </Container>
  )
}