import React, { useMemo } from "react"
import { format, addDays } from "date-fns"
import Form from "routes/LoggedIn/routes/Contests/components/Form"
import { CONTEST_KIND } from "routes/LoggedIn/routes/Contests/modules/constants"

const defaults = {
  title: "",
  description: "",
  kind: CONTEST_KIND.NORMAL,
  tags: "",
  featuredImageUrl: null,
  sponsoredBy: "",
  prizeId: "",
  maxWinners: 1,
  communityId: "",
}

export default function({ history }) {
  const startsAt = useMemo(() => format(new Date(), 'yyyy-MM-dd'), [])
  const endsAt = useMemo(() => format(addDays(new Date(), 7), 'yyyy-MM-dd'), [])
  return (
    <Form defaults={{ ...defaults, startsAt, endsAt }} history={history}/>
  )
}
