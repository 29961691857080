import React from "react"
import styled from "styled-components"
import { format } from "date-fns"
import { User, CalendarAlt } from "@styled-icons/fa-solid"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import gameResultsApi from "datasources/gameResults"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px;
  margin-bottom: 30px;
`

const filterOptions = {
  winners: { label: "Winners", queryKey: "eliminated", queryValue: false },
  eliminated: { label: "Eliminated", queryKey: "eliminated", queryValue: true }
}

const sortOptions = {
  "g.scheduledTo": "Game Date",
}

export default function() {
  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "gameResults",
  })

  const { data: gameResults, isFetching } = useApi(gameResultsApi.list, apiProps)

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {gameResults.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Image
                to={`/users/${x.userId}`}
                imageUrl={x.avatarUrl}
                fallbackIcon={User}
                alt={x.username}
              />
              <Table.Cell.Text text={x.username} />
              <Table.Cell.Text
                style={{ color: x.eliminated ? "#C4272A" : "#89C37E" }}
                text={x.eliminated ? `Eliminated` : `Winner`}
                width={180}
              />
              <Table.Cell.IconText
                icon={CalendarAlt}
                alt="Created At"
                width={180}
                text={format(new Date(x.scheduledTo), "P")}
              />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
