import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "routes/LoggedIn/routes/Products/routes/List"
import New from "routes/LoggedIn/routes/Products/routes/New"
// import Detail from "routes/LoggedIn/routes/Products/routes/Detail"
import Edit from "routes/LoggedIn/routes/Products/routes/Edit"

export default function() {
  return (
    <Switch>
      <Route exact path="/products/new" component={New}/>
      {/* <Route exact path="/products/:productId" component={Detail}/> */}
      <Route exact path="/products/:productId/edit" component={Edit}/>
      <Route exact path="/products" component={List}/>
    </Switch>
  )
}