import React from "react"
import styled from "styled-components"
import { format } from "date-fns"

import PageContainer from "components/PageContainer"
import Table from "components/Table"
import Pager from "components/Pager"
import SearchInput from "components/SearchInput"
import ToggleDropdown from "components/ToggleDropdown"
import BadgeList from "components/BadgeList"
import useApi from "hooks/useApi"
import useList from "hooks/useList"
import gamesApi from "datasources/games"

const Actions = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px auto 200px 160px;
  margin-bottom: 30px;
`

const filterOptions = {
  hidden: { label: "Hidden", queryKey: "hidden", queryValue: true },
  visible: { label: "Visible", queryKey: "hidden", queryValue: false }
}

const sortOptions = {
  createdAt: "Created At",
  scheduledTo: "ScheduledTo",
  jackpotCents: "Jackpot"
}

export default function() {
  const {
    apiProps,
    filterDropdownProps,
    filterBadgeListProps,
    sortDropdownProps,
    pagerProps,
    searchProps
  } = useList({
    filterOptions,
    sortOptions,
    cacheKey: "gamesList",
  })

  const { data: games, isFetching } = useApi(gamesApi.list, apiProps)

  return (
    <PageContainer>
      <Actions>
        <ToggleDropdown {...filterDropdownProps} />
        <SearchInput {...searchProps} />
        <ToggleDropdown {...sortDropdownProps} />
        <Pager {...pagerProps} />
      </Actions>
      <BadgeList {...filterBadgeListProps} />
      {!isFetching && (
        <Table>
          {games.map(x => (
            <Table.Row key={x.id}>
              <Table.Cell.Text text={x.name} />
              <Table.Cell.Text
                text={`$${((x.jackpotCents || 0) / 100).toFixed(2)}`}
              />
              <Table.Cell.Text text={x.hidden ? "Hidden" : "Visible"} />
              <Table.Cell.Text text={format(new Date(x.scheduledTo), "Pp")} />
              <Table.Cell.Text text={format(new Date(x.createdAt), "Pp")} />
            </Table.Row>
          ))}
        </Table>
      )}
    </PageContainer>
  )
}
