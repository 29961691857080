import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

import AppProvider from "config/AppProvider"
import ThemeProvider from "config/ThemeProvider"
import GlobalStyle from "config/GlobalStyle"
import LoaderBar from "components/LoaderBar"
import Routes from "routes"

function App() {
  return (
    <>
      <BrowserRouter>
        <AppProvider>
          <ThemeProvider>
            <GlobalStyle/>
            <LoaderBar/>
            <Routes/>
          </ThemeProvider>
        </AppProvider>
      </BrowserRouter>
    </>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
)