import { useState, useCallback } from "react"

export default function(_default = 0, { max, min = 0 }) {
  if (_default < min) {
    throw new Error("Default cannot be less than minimum")
  }

  if (_default > max) {
    throw new Error("Default cannot be greater than maximum")
  }

  const [step, setStep] = useState(_default)

  const next = useCallback(v =>
    step + 1 <= max && setStep(step + 1)
  , [step, max])

  const prev = useCallback(v =>
    step - 1 >= min && setStep(step - 1)
  , [step, min])

  const reset = useCallback(() =>
    setStep(min)
  , [min])

  return [step, { next, prev, reset }]
}