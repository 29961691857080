import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "routes/LoggedIn/routes/Events/routes/List"
import New from "routes/LoggedIn/routes/Events/routes/New"
import Detail from "routes/LoggedIn/routes/Events/routes/Detail"

export default function() {
  return (
    <Switch>
      <Route exact path="/events/new" component={New}/>
      <Route exact path="/events/:eventId" component={Detail}/>
      <Route path="/events" component={List}/>
    </Switch>
  )
}