import React from "react"
import styled from "styled-components"
import { Times } from "@styled-icons/fa-solid"

import Button from "components/Button"

const CustomButton = styled(Button.Short)`
  margin: 0 10px;
`

const Container = styled.div`
  display: flex;
  flex-grow: 0;
  margin-bottom: 30px;
  margin-left: -10px;
  margin-right: -10px;
`

export default function({ items = [], onRemove = () => {} }) {
  return (
    items.length > 0 && <Container>
      {items.map((item, i) => {
        const onClick = () => onRemove(item.key)
        return item.label ? (
          <CustomButton altStyle key={i} onClick={onClick} icon={Times} iconAlign="right">
            {item.label}
          </CustomButton>
        ) : null
      })}
    </Container>
  )
}


