import React from "react"
import styled, { css } from "styled-components"
import { NavLink } from "react-router-dom"
import { Sun, Moon } from "@styled-icons/fa-solid"

import Routes from "routes/LoggedIn/routes"
import useAuthCtx from "hooks/useAuthCtx"
import useThemeCtx from "hooks/useThemeCtx"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const NavBar = styled.div`
  background-color: ${({ theme }) => theme.menuBgColor};
  display: flex;
  height: 60px;
  padding: 0 30px;
  width: 100%;
`

const navBarItemCss = css`
  background-color: ${({ theme }) => theme.menuBgColor};
  color: ${({ theme }) => theme.menuTxtColor};
  cursor: pointer;
  display: block;
  font-size: 18px;
  line-height: 60px;
  padding: 0 20px;
  &:hover {
    background-color: ${({ theme }) => theme.menuItemSelBgColor};
    color: ${({ theme }) => theme.menuItemSelTxtColor};
  }
`

const activeClassName = "nav-item-active"
const NavBarLinkItem = styled(NavLink).attrs({ activeClassName })`
  ${navBarItemCss}
  &.${activeClassName} {
    background-color: ${({ theme }) => theme.menuItemSelBgColor};
    color: ${({ theme }) => theme.menuItemSelTxtColor};
  }
`

const NavBarPusher = styled.div`
  margin-left: auto;
  width: 0;
`

const NavBarButtonItem = styled.div`
  ${navBarItemCss}
`

export default function() {
  const { roles, deauth } = useAuthCtx()
  const { theme, setTheme } = useThemeCtx()

  return (
    <Container>
      <NavBar>
        <NavBarLinkItem to="/users">Users</NavBarLinkItem>
        {/* <NavBarLinkItem to="/checkins">Check-ins</NavBarLinkItem> */}
        <NavBarLinkItem to="/products">Products</NavBarLinkItem>
        {/* {roles.triviaAdmin &&
          <NavBarLinkItem to="/questions">Questions</NavBarLinkItem>
        } */}
        {roles.triviaAdmin &&
          <NavBarLinkItem to="/transactions">Transactions</NavBarLinkItem>
        }
        {/* {roles.triviaAdmin &&
          <NavBarLinkItem to="/games">Games</NavBarLinkItem>
        } */}
        {/* {roles.triviaAdmin &&
          <NavBarLinkItem to="/game-results">Game Results</NavBarLinkItem>
        } */}
        {roles.triviaAdmin &&
          <NavBarLinkItem to="/prizes">Prizes</NavBarLinkItem>
        }
        {/* {roles.triviaAdmin &&
          <NavBarLinkItem to="/events">Events</NavBarLinkItem>
        } */}
        {roles.triviaAdmin &&
          <NavBarLinkItem to="/streams">Streams</NavBarLinkItem>
        }
        {roles.triviaAdmin &&
          <NavBarLinkItem to="/contests">Contests</NavBarLinkItem>
        }
        {roles.triviaAdmin &&
          <NavBarLinkItem to="/tags">Tags</NavBarLinkItem>
        }
        {roles.triviaAdmin &&
          <NavBarLinkItem to="/communities">Communities</NavBarLinkItem>
        }
        <NavBarPusher/>
        {theme === "darkTheme" && <NavBarButtonItem onClick={() => setTheme("lightTheme")}><Sun size="20"/></NavBarButtonItem>}
        {theme === "lightTheme" && <NavBarButtonItem onClick={() => setTheme("darkTheme")}><Moon size="20"/></NavBarButtonItem>}
        <NavBarButtonItem onClick={deauth}>Logout</NavBarButtonItem>
      </NavBar>
      <Routes />
    </Container>
  )
}
