import React, { useMemo } from "react"

import Select from "components/Select"
import communitiesApi from "datasources/communities"
import useApi from "hooks/useApi"

export default function ({ field = {} }) {

  const { data, isFetching } = useApi(communitiesApi.list, { perPage: 50, page: 1, filters: { published: true } })

  const communitiesOptions = useMemo(() => {
    if (data && data.length > 0) {
      return data.map(({ id, name }) => ({ id, name }))
    }
    return []
  }, [data])

  return <Select disabled={isFetching} label="Community" options={communitiesOptions} {...field}/>
}
