import React, { useMemo } from 'react'
import styled from 'styled-components'

import PageContainer from 'components/PageContainer'
import Button from 'components/Button'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import MetadataInput from 'components/MetadataInput'
import MultiFileInput from 'components/MultiFileInput'
import Select from 'components/Select'

import useApi from 'hooks/useApi'
import useForm from 'hooks/useForm'
import useAppCtx from 'hooks/useAppCtx'

import communitiesApi from 'datasources/communities'
import productsApi from 'datasources/products'
import usersApi from 'datasources/users'
import uploadsApi from 'datasources/uploads'

const HTMLForm = styled.form`
  background-color: ${({ theme }) => theme.formBgColor};
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 30px;
`

const constraints = {}

const stateOptions = [
  { id: '', name: 'All' },
  { id: 'US-*', name: 'United States' },
  { id: 'CA-*', name: 'Canada' },
  { id: 'UK-*', name: 'United Kingdom' },
  { id: 'AU-*', name: 'Australia' },
  { id: 'ZA-*', name: 'South Africa' },
  { id: 'NZ-*', name: 'New Zealand' },
  { id: 'US-AL', name: 'Alabama' },
  { id: 'US-AK', name: 'Alaska' },
  { id: 'US-AZ', name: 'Arizona' },
  { id: 'US-AR', name: 'Arkansas' },
  { id: 'US-CA', name: 'California' },
  { id: 'US-CO', name: 'Colorado' },
  { id: 'US-CT', name: 'Connecticut' },
  { id: 'US-DE', name: 'Delaware' },
  { id: 'US-DC', name: 'District of Columbia' },
  { id: 'US-FL', name: 'Florida' },
  { id: 'US-GA', name: 'Georgia' },
  { id: 'US-HI', name: 'Hawaii' },
  { id: 'US-ID', name: 'Idaho' },
  { id: 'US-IL', name: 'Illinois' },
  { id: 'US-IN', name: 'Indiana' },
  { id: 'US-IA', name: 'Iowa' },
  { id: 'US-KS', name: 'Kansas' },
  { id: 'US-KY', name: 'Kentucky' },
  { id: 'US-LA', name: 'Louisiana' },
  { id: 'US-ME', name: 'Maine' },
  { id: 'US-MD', name: 'Maryland' },
  { id: 'US-MA', name: 'Massachusetts' },
  { id: 'US-MI', name: 'Michigan' },
  { id: 'US-MN', name: 'Minnesota' },
  { id: 'US-MS', name: 'Mississippi' },
  { id: 'US-MO', name: 'Missouri' },
  { id: 'US-MT', name: 'Montana' },
  { id: 'US-NE', name: 'Nebraska' },
  { id: 'US-NV', name: 'Nevada' },
  { id: 'US-NH', name: 'New Hampshire' },
  { id: 'US-NJ', name: 'New Jersey' },
  { id: 'US-NM', name: 'New Mexico' },
  { id: 'US-NY', name: 'New York' },
  { id: 'US-NC', name: 'North Carolina' },
  { id: 'US-ND', name: 'North Dakota' },
  { id: 'US-OH', name: 'Ohio' },
  { id: 'US-OK', name: 'Oklahoma' },
  { id: 'US-OR', name: 'Oregon' },
  { id: 'US-PA', name: 'Pennsylvania' },
  { id: 'US-RI', name: 'Rhode Island' },
  { id: 'US-SC', name: 'South Carolina' },
  { id: 'US-SD', name: 'South Dakota' },
  { id: 'US-TN', name: 'Tennessee' },
  { id: 'US-TX', name: 'Texas' },
  { id: 'US-UT', name: 'Utah' },
  { id: 'US-VT', name: 'Vermont' },
  { id: 'US-VA', name: 'Virginia' },
  { id: 'US-WA', name: 'Washington' },
  { id: 'US-WV', name: 'West Virginia' },
  { id: 'US-WI', name: 'Wisconsin' },
  { id: 'US-WY', name: 'Wyoming' },
]

function isBase64(s) {
  return s ? s.indexOf('data:') > -1 : false
}

export default function ({ defaults, history }) {
  const ctx = useAppCtx()

  const { fields, values, setAllErrors, onSubmit } = useForm({
    constraints,
    defaults,
  })

  const communityIdApiFilter = useMemo(() => {
    return {
      filters: {
        communityId: values.communityId,
      },
    }
  }, [values.communityId])

  const { data: communities, isFetching: isFetchingCommunities } = useApi(
    communitiesApi.list
  )
  const { data: partners, isFetching: isFetchingPartners } = useApi(
    usersApi.listPartners,
    communityIdApiFilter
  )
  const { data: categories, isFetching: isFetchingCategories } = useApi(
    productsApi.listCategories,
    communityIdApiFilter
  )

  const categoryOptions = useMemo(() => {
    if (categories && categories.length > 0) {
      return [
        { id: '', name: '' },
        ...categories.map(({ id, name }) => ({ id, name })),
      ]
    }
    return []
  }, [categories])

  const partnerOptions = useMemo(() => {
    if (partners && partners.length > 0) {
      return [
        { id: '', name: '' },
        ...partners.map(({ id, username }) => ({ id, name: username })),
      ]
    }
    return []
  }, [partners])

  const communityOptions = useMemo(() => {
    if (communities && communities.length > 0) {
      return [{ id: '', name: '' }].concat(
        communities.map(({ id, name }) => ({ id, name }))
      )
    }
    return []
  }, [communities])

  async function submitClicked() {
    try {
      let product = { ...values }
      product.priceCents = Math.round(product.price * 100)
      product.states = product.state ? [product.state] : []
      product.metadata = {
        fields: values.metadata,
      }
      delete product.state
      delete product.price
      // This is taken from the partner
      delete product.communityId

      for (let i = 0; i <= product.images.length; i++) {
        let image = product.images[i]
        if (isBase64(image)) {
          const path = `products/${
            !product.id ? 'ownerId/image' : `${product.id}/image`
          }`
          const base64 = image
          product.images[i] = await uploadsApi.post(ctx, {
            path,
            base64,
            orphan: !product.id,
          })
        }
      }

      if (product.id) {
        await productsApi.patch(ctx, product)
      } else {
        await productsApi.post(ctx, product)
      }

      history.push('/products')
    } catch (e) {
      setAllErrors(e)
    }
  }

  return (
    <PageContainer onBack={() => history.push('/products')}>
      <HTMLForm onSubmit={onSubmit(submitClicked)}>
        {isFetchingCommunities ? (
          <Select label='Community' disabled />
        ) : (
          <Select
            label='Community'
            options={communityOptions}
            {...fields.communityId}
          />
        )}
        <Input label='Name' {...fields.name} />
        <TextArea rows={5} label='Description' {...fields.description} />
        <Input label='MSRP' {...fields.price} />
        <Input label='Website' {...fields.url} />
        <Select label='State' options={stateOptions} {...fields.state} />
        {!isFetchingPartners && (
          <Select
            label='Partner'
            options={partnerOptions}
            {...fields.partnerId}
          />
        )}
        {!isFetchingCategories && (
          <Select
            label='Category'
            options={categoryOptions}
            {...fields.categoryId}
          />
        )}
        <MetadataInput label='Metadata' {...fields.metadata} />
        <MultiFileInput label='Photos' {...fields.images} />
        <Button.Flex>Save</Button.Flex>
      </HTMLForm>
    </PageContainer>
  )
}
